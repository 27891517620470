import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Page from '../../../components/Page';
import {
    Button, Card,
    Container,
    Grid,
    Stack,
    Paper,
    Typography,
    CircularProgress,
    Backdrop, Chip
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import Iconify from "../../../components/Iconify";
import SubscriptionDialog from './SubscriptionDialog';
import EcheancePaymentDialog from './EcheancePaymentDialog';
import * as ApiSubscription from "../../../api/subscription";
import * as ApiCommon from "../../../api/common";
import * as ApiCustomer from "../../../api/customer";
import {useSwal} from "../../../hooks/swal";
import * as ppUtils from '../../../libs/utils';


const Subscription = () => {

    const swal = useSwal();

    const navigate = useNavigate();

    const [subscriptionFetchData, setSubscriptionFetchData] = useState([]);

    const [myTaxiOfferData, setMyTaxiOfferData] = useState([]);

    const [dialogEcheancePaymentSubscriptionItem, setDialogEcheancePaymentSubscriptionItem] = useState(null);

    const [isDialogEchencePaymentOpen, setIsDialogEchencePaymentOpen] = useState(false);

    const [dialogSubscriptionCustomerData, setDialogSubscriptionCustomerData] = useState(null);

    const [isDialogSubscriptionOpen, setIsDialogSubscriptionOpen] = useState(false);

    const [isLoaderShow, setIsLoaderShow] = useState(false);

    const tableColumns = [
        {
            field: '_id',
            headerName: 'ID',
            width: 300,
            renderCell: (params) => (
                <>
                    <Button 
                        href={`${process.env.REACT_APP_BACKEND_URL}/reports/contract/${params.row._id}`}
                        target="_blank" size="small" 
                        variant="contained"
                    >
                        contrat
                    </Button>
                    <Button 
                        sx={{ml: 1}}
                        href={`${process.env.REACT_APP_BACKEND_URL}/reports/contract/${params.row._id}/_download`}
                        target="_blank" size="small" 
                        variant="contained"
                    >
                        Tel contrat
                    </Button>
                    <Button sx={{ml: 1}}  href={`/backoffice/subscription-sheet/${params.row._id}`} target="_blank" size="small" variant="contained">info</Button>
                </>
            )
        },
        {field: 'reference_code', headerName: 'Reference', width: 150},
        {
            field: 'my_taxi_offer_id',
            headerName: 'Offre Mon Taxi',
            renderCell: (params) => (<Chip label={params.row.my_taxi_offer_id.label} />),
            width: 150
        },
        {
            field: 'paymentByEcheance',
            headerName: 'Paiement Par Echeance ?',
            renderCell: (params) => (params.row.echeance_payment_status.is_actived ? (<Chip label={`OUI - ${params.row.echeance_payment_status.paid_echeance_count} / ${params.row.echeance_payment_status.number_echeance_chosen}`} />) : (<Chip label="NON" />)),
            width: 200
        },
        {
            field: 'offer_cost_payment_completed_at',
            headerName: 'Paiement Soldé?',
            renderCell: (params) => {
                return !params.row.offer_cost_payment_completed_at ?
                    (
                        <>
                            <Chip label="NON" /> <Button onClick={() => {handleButtonAddEchencePaymentClick(params.row)}} sx={{ml: 1}} size="small" variant="contained">Paiement d'écheance</Button>
                        </>
                    )
                    :
                    (
                        <Chip label={`OUI - le ${ppUtils.formatDate(params.row.offer_cost_payment_completed_at)}`} />
                    )
            },
            width: 300
        },
        {
            field: 'subscribed_at',
            headerName: 'Date Souscription',
            valueGetter: (params) => (ppUtils.formatDateTime(params.row.subscribed_at)),
            width: 150
        },
        {
            field: 'user_customer_id',
            headerName: 'Nom & Prenom',
            valueGetter: (params) => (`${params.row.user_customer_id.last_name} ${params.row.user_customer_id.first_name}`),
            width: 150
        },
        {
            field: 'matricule',
            headerName: 'Matricule Client',
            valueGetter: (params) => params.row.user_customer_id.matricule,
            width: 200
        },
        {
            field: 'is_online_subscription',
            headerName: 'Souscription en Agence ?',
            renderCell: (params) => (!!params.row.is_online_subscription ? (<Chip label="NON" />)  : (<Chip label={`Oui par ${params.row.user_admin_id?.last_name} ${params.row.user_admin_id?.first_name}`} />)),
            width: 250
        }
    ];

    const handleDialogSouscriptionClose = useCallback(() => {
        setIsDialogSubscriptionOpen(false);
        setDialogSubscriptionCustomerData(null);
        fetchSubscription();
    }, []);

    const handleDialogEchencePaymentClose = useCallback(() => {
        setIsDialogEchencePaymentOpen(false);
        setDialogEcheancePaymentSubscriptionItem(null);
        fetchSubscription();
    }, []);

    const handleButtonAddSubscriptionClick = useCallback(() => {

        swal.fire({
            title: 'Entrez le matricule du client',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Valider',
            showLoaderOnConfirm: true,
            backdrop: true,
            preConfirm: (matricule) => {
                return ApiCustomer.findOneCustomerByMatricule(matricule)
                    .then((data) => {
                        return data;
                    })
                    .catch((err) => {
                        if (err.response){
                            swal.showValidationMessage(`Erreur !!! - ${err.response.data.message}`);
                        }
                        else{
                            swal.showValidationMessage(`Erreur !!! - Impossible de traiter la requête`);
                        }
                    })
            },
            allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
            if (!result.isConfirmed) {
                return;
            }

            swal.fire({
                title: 'Continuer ?',
                text: `Client: ${result.value.last_name} ${result.value.first_name}`,
                icon: 'warning',
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonText: 'Continuer',
                cancelButtonText: 'Annuler',
            })
                .then((rp) => {
                    if(!rp.isConfirmed) {
                        return;
                    }
                    setDialogSubscriptionCustomerData(result.value);
                    setIsDialogSubscriptionOpen(true);
                })
        })

    }, []);

    const handleButtonAddEchencePaymentClick = useCallback((subscriptionItem) => {
        setDialogEcheancePaymentSubscriptionItem(subscriptionItem);
        setIsDialogEchencePaymentOpen(true);
    }, []);

    const handleButtonRefreshTableClick = useCallback(() => {
        fetchSubscription();
    }, []);

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const fetchSubscription = () => {
        showLoader();

        ApiSubscription.findAllSubscription()
            .then((data) => {
                setSubscriptionFetchData(data);
            })
            .catch((err) => {
                if (err.response){
                    swal.fire({
                        icon: 'error',
                        title: 'Erreur !!',
                        text: err.response.data.message
                    }).then(() => {});
                    return;
                }

                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: 'Impossible de traiter la requête'
                }).then(() => {});
            })
            .finally(() => {
                hideLoader();
            })
    };

    useEffect(() => {
        showLoader();
        Promise.all([ApiSubscription.findAllSubscription(), ApiCommon.getTaxiOffer()])
            .then(results => {
                setSubscriptionFetchData(results[0]);
                setMyTaxiOfferData(results[1]);
            })
            .catch((err) => {
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Une erreur s'est produite"
                }).then(() => {
                    navigate(-1, {replace: true});
                });
            })
            .finally(() => hideLoader())
    }, []);


    return (
        <Page title="Backoffice | Souscription">
            <Container>

                <Typography variant="h4" sx={{mb: 5, fontFamily: 'default'}}>
                    SOUSCRIPTION
                </Typography>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="mdi:refresh" />}
                        onClick={handleButtonRefreshTableClick}
                    >
                        Actualisé
                    </Button>

                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={handleButtonAddSubscriptionClick}
                        sx={{ml: 2}}
                    >
                        Nouvelle Souscription
                    </Button>
                </Stack>
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row._id}
                        columns={tableColumns}
                        rows={subscriptionFetchData}
                        components={{ Toolbar: GridToolbar }}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        disableSelectionOnClick
                        rowsPerPageOptions={[20, 40, 50, 100]}
                    />
                </div>

            </Container>

            <SubscriptionDialog
                onClose={handleDialogSouscriptionClose}
                open={isDialogSubscriptionOpen}
                taxiOfferData={myTaxiOfferData}
                customerData={dialogSubscriptionCustomerData}
            />

            <EcheancePaymentDialog
                onClose={handleDialogEchencePaymentClose}
                open={isDialogEchencePaymentOpen}
                subscriptionItem={dialogEcheancePaymentSubscriptionItem}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Page>
    );
};

export default Subscription;
