import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    token: null,
    user: null // {_id: '', first_name: ''}
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        signIn: (state, action) => {
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        signOut: (state, action) => {
            state.isAuthenticated = false;
            state.token = null;
            state.user = null;
        }
    }
});

export const {signIn, signOut} = authSlice.actions;


export default authSlice.reducer;
