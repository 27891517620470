import {callApi, callApiWithFormData} from './_axios';

export const getLastCommissionRegisteringBulk = async () => {
    const resp = await callApi('/commission-registering/bulk-insert', 'get', {});
    return resp.data;
};

export const addCommissionRegisteringBulk = async (formData) => {
    const res = await callApiWithFormData("/commission-registering/bulk-insert", "post", formData);
    return res.data;
}

export const addCommissionRegisteringUniq = async (formData) => {
    const res = await callApi("/commission-registering/uniq-insert", "post", formData);
    return res.data;
}


