import {callApi} from './_axios';

export const findAllCustomer = async () => {
    const res = await callApi("/customer", "get", {});
    return res.data;
}

export const findOneCustomer = async (idCustomer) => {
    const res = await callApi("/customer/" + String(idCustomer), "get", {});
    return res.data;
}

export const findOneCustomerByMatricule = async (customerMatricule) => {
    const res = await callApi("/customer/find-by-matricule/" + String(customerMatricule), "get", {});
    return res.data;
}

export const resendAccountConfirmationEmail = async (customerId) => {
    const res = await callApi("/customer/resend-account-confirmation-email/" + String(customerId), "get", {});
    return res.data;
}

export const addCustomer = async (userData) => {
    const res = await callApi("/customer", "post", userData);
    return res.data;
}

export const updateCustomer = async (userData) => {

    const {_id: userId, ...otherData} = userData;

    const res = await callApi("/customer/" + String(userId), "put", otherData);
    return res.data;
}

