import {callApi} from "./_axios";


/* AUTH */

export const signIn = async (email, password) => {
    const resp = await callApi('/auth/signin', 'post', {username: email, password});
    return resp.data;
};


