import React, {useMemo, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {
    Button,
    Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent,
    Stack,
    TextField, CircularProgress, Backdrop
} from "@mui/material";
import {useSwal} from '../../../hooks/swal';
import * as ApiCommissionRegistering from "../../../api/commission_registering";
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import "moment/locale/fr";



const CommissionRegisteringDialogBulk = ({open, onClose}) => {

    const swal = useSwal();

    const [formPeriodField, setFormPeriodField] = useState(null);

    const [formCommentField, setFormCommentField] = useState("");

    const formCommissionFileFieldRef = useRef("");

    const [isLoaderShow, setIsLoaderShow] = useState(false);

    const handleFormPeriodFieldChange = (value) => {
        setFormPeriodField(value);
    };

    const handleFormCommentFieldChange = (e) => {
        setFormCommentField(e.target.value);
    };

    const handleClose = () => {
        setFormPeriodField(null);
        setFormCommentField("");
        formCommissionFileFieldRef.current = null;
        onClose();
    };

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const handleFormSubmit = () => {


        if (!formPeriodField){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Le période est obligatoire"
            }).then(() => {});
            return;
        }


        if (!formCommissionFileFieldRef.current.files[0]){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Le fichier de commission est obligatoire"
            }).then(() => {});
            return;
        }


        swal.fire({
            title: 'Continuer ?',
            text: "Vous êtes sur le point de ",
            icon: 'warning',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        })
            .then((rp) => {
                if(!rp.isConfirmed) {
                    return;
                }

                const formData = new FormData();

                formData.append("periode", formPeriodField);
                formData.append("comment", formCommentField);
                formData.append("file", formCommissionFileFieldRef.current.files[0]);

                showLoader();

                ApiCommissionRegistering.addCommissionRegisteringBulk(formData)
                    .then((data) => {
                        handleClose();

                        swal.fire({
                            icon: 'success',
                            title: "",
                            text: "L'opération à été effectuée avec succès"
                        }).then(() => {});

                    })
                    .catch((err) => {

                        if (err.response){
                            swal.fire({
                                icon: 'error',
                                title: 'Erreur !!',
                                text: err.response.data.message
                            }).then(() => {});
                            return;
                        }

                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: 'Impossible de traiter la requête'
                        }).then(() => {});

                    })
                    .finally(() => {
                        hideLoader();
                    })
            })
    };


    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle> Commission Bulk</DialogTitle>
                <DialogContent>

                    <DialogContentText sx={{mb: 4}}>
                    </DialogContentText>

                    <Stack spacing={3}>

                        <LocalizationProvider dateAdapter={DateAdapter} local="fr">
                            <DatePicker
                                views={['year', 'month']}
                                label="Période"
                                value={formPeriodField}
                                onChange={handleFormPeriodFieldChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                        <input ref={formCommissionFileFieldRef} type="file" name="file" accept="text/csv" />

                        <TextField
                            fullWidth
                            type="text"
                            label="Commentaire"
                            value={formCommentField}
                            onChange={handleFormCommentFieldChange}
                        />

                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClose}>Annuler</Button>
                    <Button variant="contained" color="success" onClick={handleFormSubmit}>Valider</Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}


CommissionRegisteringDialogBulk.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CommissionRegisteringDialogBulk;
