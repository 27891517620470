import React, {useMemo, useState, useEffect} from "react";
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import {
    Button,
    Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent,
    MenuItem, FormControlLabel,
    Grid, TextField, Checkbox, InputAdornment, IconButton, CircularProgress, Backdrop
} from "@mui/material";
import * as Yup from "yup";
import Iconify from "../../../components/Iconify";
import {useSwal} from '../../../hooks/swal';
import * as ApiCustomer from './../../../api/customer';
import * as ApiCommon from './../../../api/common';


const userCustomerSchema = Yup.object().shape({
    first_name: Yup.string().required("Le prenom est obligatoire"),
    last_name: Yup.string().required("Le nom est obligatoire"),
    country_code: Yup.string().required("Le pays est obligatoire"),
    city: Yup.string().required("La ville est obligatoire"),
    email: Yup.string().email("l'email est invalide").required("L'email est obligatoire"),
    phone_number: Yup.number().typeError("Le contact doit être constitué uniquement de chiffre").required("Le contact est obligatoire"),
    address: Yup.string().required("L'adresse est obligatoire"),
    zip_code: Yup.string().min(5, "Le zip code doit avoir 5 caractères").max(5, "Le zip code doit avoir 5 caractères").required("Le zip code est obligatoire"),
    bank_rib: Yup.string(),
    password: Yup.string().required("Le mot de passe est obligatoire").min(8, "Le mot de passe doit savoir au moin 8 caractères"),
});


const CustomerDialog = ({open, onClose, userData, readOnly}) => {

    const [showPassword, setShowPassword] = useState(false);

    const [isLoaderShow, setIsLoaderShow] = useState(false);

    const swal = useSwal();

    const [countriesData, setCountriesData] = useState([]);

    const handleClose = () => {
        formik.resetForm();
        onClose();
    };

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const showLoader = () => {
        setIsLoaderShow(true);
    };

    const hideLoader = () => {
        setIsLoaderShow(false);
    };

    useEffect(() => {

        showLoader();

        ApiCommon.getCountry()
            .then((data) => {
                setCountriesData(data);
                hideLoader();
            })
            .catch(err => {
                hideLoader()
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Une erreur s'est produite"
                }).then(() => {
                    onClose();
                });
            });

    }, []);

    const isAddNewUserMode = !userData._id;

    let defaultFormCountryCode = "";

    if (userData && userData.country_id && userData.country_id.code){
        defaultFormCountryCode = userData.country_id.code;
    }

    const formik = useFormik({
        initialValues: {
            _id: !userData._id ? null : userData._id,
            first_name: !userData.first_name ? "" : userData.first_name,
            last_name: !userData.last_name ? "" : userData.last_name,
            country_code: defaultFormCountryCode,
            city: !userData.city ? "" : userData.city,
            email: !userData.email ? "" : userData.email,
            phone_number: !userData.phone_number ? "" : userData.phone_number,
            address: !userData.address ? "" : userData.address,
            zip_code: !userData.zip_code ? "00000" : userData.zip_code,
            bank_rib: !userData.bank_rib ? "" : userData.bank_rib,
            password: !isAddNewUserMode ? "xxxxxxxxx" : "",
            password2: !isAddNewUserMode ? "xxxxxxxxx" : "",
        },
        validationSchema: userCustomerSchema,
        onSubmit: async (values) => {

            if (isAddNewUserMode && !readOnly){

                if (!values.password || !values.password2){
                    swal.fire({
                        icon: 'error',
                        title: 'Erreur !!',
                        text: "Le mot de passe est obligatoire"
                    }).then(() => {});
                    return;
                }

                if (values.password !== values.password2){
                    swal.fire({
                        icon: 'error',
                        title: 'Erreur !!',
                        text: "Le mot de passe non identique"
                    }).then(() => {});
                    return;
                }

                if(values.password.length < 8){
                    swal.fire({
                        icon: 'error',
                        title: 'Erreur !!',
                        text: "Le mot de passe doit avoir au minimun 8 caractères"
                    }).then(() => {});
                    return;
                }
            }

            const operation_t1 = !values._id  ? "d'Ajouter" : "de Modifier";

            swal.fire({
                title: 'Continuer ?',
                text: "Vous êtes sur le point "+ operation_t1 +" un nouveau client",
                icon: 'warning',
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonText: 'Continuer',
                cancelButtonText: 'Annuler',
            })
                .then((rp) => {
                    if(!rp.isConfirmed) {
                        return;
                    }

                    showLoader();

                    const apiSubmit = isAddNewUserMode ? ApiCustomer.addCustomer : ApiCustomer.updateCustomer

                    apiSubmit(values)
                        .then((data) => {

                            handleClose();

                            swal.fire({
                                icon: 'success',
                                title: !isAddNewUserMode ? "Modification du Client" : "Ajout de Client",
                                text: "L'opération à été effectuée avec succès"
                            }).then(() => {});

                        })
                        .catch((err) => {

                            if (err.response){
                                swal.fire({
                                    icon: 'error',
                                    title: 'Erreur !!',
                                    text: err.response.data.message
                                }).then(() => {});
                                return;
                            }

                            swal.fire({
                                icon: 'error',
                                title: 'Erreur !!',
                                text: 'Impossible de traiter la requête'
                            }).then(() => {});

                        })
                        .finally(() => {
                            hideLoader();
                        })
                })
        },
        enableReinitialize: true
    });

    const { errors, touched, values, setValues, isSubmitting, handleSubmit, getFieldProps, isValid, submitForm } = formik;

    const formRo = useMemo(() => {

        if (!values.country_code){

            return {
                dial_code: "",
                default_state: ""
            };
        }

        const selectedCountry = countriesData.find((item) => item.code === values.country_code);

        if (!selectedCountry){

            return {
                dial_code: "",
                default_state: ""
            };
        }

        return {
            dial_code: selectedCountry.dial_code,
            default_state: selectedCountry.code
        }

    }, [values.country_code]);


    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle> {!!readOnly ? "" : (isAddNewUserMode) ? "Nouveau Client" : "Modifier un  Client"} </DialogTitle>
                <DialogContent>

                    <DialogContentText sx={{mb: 4}}>
                    </DialogContentText>

                    <FormikProvider value={formik}>

                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={3}>


                                <Grid hidden={!readOnly} item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Matricule"
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        value={userData.matricule}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        fullWidth
                                        type="text"
                                        label="Nom"
                                        {...getFieldProps('last_name')}
                                        error={Boolean(touched.last_name && errors.last_name)}
                                        helperText={touched.last_name && errors.last_name}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        fullWidth
                                        type="text"
                                        label="Prenom"
                                        {...getFieldProps('first_name')}
                                        error={Boolean(touched.first_name && errors.first_name)}
                                        helperText={touched.first_name && errors.first_name}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        fullWidth
                                        type="email"
                                        label="Email"
                                        {...getFieldProps('email')}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        fullWidth
                                        select
                                        label="Pays"
                                        {...getFieldProps('country_code')}
                                        error={Boolean(touched.country_code && errors.country_code)}
                                        helperText={touched.country_code && errors.country_code}
                                    >

                                        {countriesData.map((option) => (
                                            <MenuItem key={option.code} value={option.code}>
                                                {option.name}
                                            </MenuItem>
                                        ))}

                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        fullWidth
                                        type="text"
                                        label="Ville"
                                        {...getFieldProps('city')}
                                        error={Boolean(touched.city && errors.city)}
                                        helperText={touched.city && errors.city}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        fullWidth
                                        type="text"
                                        label="Etat"
                                        value={values.country_code}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Prefix"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        value={formRo.dial_code}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        fullWidth
                                        type="text"
                                        label="Contact"
                                        {...getFieldProps('phone_number')}
                                        error={Boolean(touched.phone_number && errors.phone_number)}
                                        helperText={touched.phone_number && errors.phone_number}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        fullWidth
                                        type="text"
                                        label="Adresse"
                                        {...getFieldProps('address')}
                                        error={Boolean(touched.address && errors.address)}
                                        helperText={touched.address && errors.address}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        fullWidth
                                        type="text"
                                        label="Zip Code"
                                        {...getFieldProps('zip_code')}
                                        error={Boolean(touched.zip_code && errors.zip_code)}
                                        helperText={touched.zip_code && errors.zip_code}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        fullWidth
                                        type="text"
                                        label="RIB Bancaire"
                                        {...getFieldProps('bank_rib')}
                                        error={Boolean(touched.bank_rib && errors.bank_rib)}
                                        helperText={touched.bank_rib && errors.bank_rib}
                                    />
                                </Grid>

                                {(isAddNewUserMode && !readOnly) && (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                type={showPassword ? 'text' : 'password'}
                                                label="Mot de Passe"
                                                {...getFieldProps('password')}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={handleShowPassword} edge="end">
                                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                error={Boolean(touched.password && errors.password)}
                                                helperText={touched.password && errors.password}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                type={showPassword ? 'text' : 'password'}
                                                label="Confirmer le mot de passe"
                                                {...getFieldProps('password2')}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={handleShowPassword} edge="end">
                                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                error={Boolean(touched.password2 && errors.password2)}
                                                helperText={touched.password2 && errors.password2}
                                            />
                                        </Grid>
                                    </>
                                )}


                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClose}>Annuler</Button>

                    {!readOnly && (
                        <>
                            <Button disabled={!isValid} variant="contained" color="success" onClick={submitForm}>Valider</Button>
                        </>
                    )}

                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

CustomerDialog.defaultProps = {
    readOnly: false,
    userData: {
        _id: null,
        first_name: "",
        last_name: "",
        country_code: "",
        city: "",
        state: "",
        email: "",
        phone_number: "",
        address: "",
        zip_code: "",
        bank_rib: ""
    }
};

CustomerDialog.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    userData: PropTypes.shape({
        _id: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        country_code: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        email: PropTypes.string,
        phone_number: PropTypes.string,
        address: PropTypes.string,
        zip_code: PropTypes.string,
        bank_rib: PropTypes.string,
    }).isRequired
};

export default CustomerDialog;
