import React, {useState, useEffect, useCallback} from 'react';
import Page from '../../../components/Page';
import {
    Backdrop,
    Button, CircularProgress,
    Container,
    Grid,
    Stack, TextField,
    Typography,
} from "@mui/material";
import * as apiUser from "../../../api/user";
import {useSwal} from "../../../hooks/swal";
import {useNavigate} from 'react-router-dom';
import CustomerPasswordChangeDialog from "./CustomerPasswordChangeDialog";

const Profile = () => {
    const swal = useSwal();
    const navigate = useNavigate();

    const [userData, setUserData] = useState(null);
    const [isLoaderShow, setIsLoaderShow] = useState(false);
    const [isDialogPasswordOpen, setIsDialogPasswordOpen] = useState(false);

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const handleDialogCustomerClose = useCallback(() => {
        setIsDialogPasswordOpen(false);
    }, []);

    const handleShowDialogButtonClick = useCallback(() => {
        setIsDialogPasswordOpen(true);
    }, []);

    useEffect(() => {

        showLoader();

        apiUser.getLoginUser()
            .then(data => {
                setUserData(data);
            })
            .catch(err => {
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Une erreur s'est produite"
                }).then(() => {
                    navigate(-1, {replace: true})
                });
            })
            .finally(() => {
                hideLoader();
            })
    }, []);

    if (!userData) {
        return (
            <>
                <Page title="Backoffice Admin: Profile">
                    <Container>
                        <CircularProgress color="inherit" />
                    </Container>
                </Page>
            </>
        );
    }

    return (
        <Page title="Backoffice | Profile">
            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    PROFILE
                </Typography>

                <Grid container spacing={3} sx={{justifyContent: "center", mb: 2}}>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={3}>
                            <TextField
                                InputProps={{
                                    readOnly: true
                                }}
                                fullWidth
                                type="text"
                                label="Username"
                                value={userData.username}
                            />
                            <TextField
                                InputProps={{
                                    readOnly: true
                                }}
                                fullWidth
                                type="text"
                                label="Nom"
                                value={userData.last_name}
                            />
                            <TextField
                                InputProps={{
                                    readOnly: true
                                }}
                                fullWidth
                                type="text"
                                label="Prenom"
                                value={userData.first_name}
                            />
                            <TextField
                                InputProps={{
                                    readOnly: true
                                }}
                                fullWidth
                                type="text"
                                label="Email"
                                value={userData.email}
                            />
                        </Stack>

                    </Grid>

                </Grid>

                <p style={{textAlign: "center"}}>
                    <Button variant="contained" onClick={handleShowDialogButtonClick}>Changer de mot de passe</Button>
                </p>

            </Container>

            <CustomerPasswordChangeDialog onClose={handleDialogCustomerClose} open={isDialogPasswordOpen} />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Page>
    );
};

export default Profile;
