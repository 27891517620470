import React, {useMemo, useState} from "react";
import PropTypes from 'prop-types';
import {
    Button, Card, Switch,
    Container,
    Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent,
    Stack, Box,
    Paper,
    FormControl, Select, InputLabel, MenuItem, FormControlLabel,
    Typography,
    Grid, TextField, Checkbox, InputAdornment, IconButton, CircularProgress, Backdrop
} from "@mui/material";
import * as Yup from "yup";
import Iconify from "../../../components/Iconify";
import {useSwal} from '../../../hooks/swal';
import * as apiUser from "../../../api/user";


const CustomerPasswordChangeDialog = ({open, onClose}) => {

    const [showPassword, setShowPassword] = useState(false);

    const [isLoaderShow, setIsLoaderShow] = useState(false);

    const [formPasswordData, setFormPasswordData] = useState({
        old_password: "",
        new_password: "",
        new_password2: ""
    });

    const swal = useSwal();

    const handleFormPasswordFieldChange = (e) => {

        if (e.target.name === "old_password"){
            setFormPasswordData((state) => ({...state, old_password: e.target.value}));
        }
        else if(e.target.name === "new_password"){
            setFormPasswordData((state) => ({...state, new_password: e.target.value}));
        }
        else if(e.target.name === "new_password2"){
            setFormPasswordData((state) => ({...state, new_password2: e.target.value}));
        }
        else {
        }

    };

    const handleClose = () => {
        setFormPasswordData({
            old_password: "",
            new_password: "",
            new_password2: ""
        });
        onClose();
    };

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const handleFormPasswordSubmit = (e) => {

        e.preventDefault();

        if (!formPasswordData.old_password || !formPasswordData.new_password || !formPasswordData.new_password2){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Le mot de passe est obligatoire"
            }).then(() => {});
            return;
        }

        if (formPasswordData.new_password !== formPasswordData.new_password2){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Le mot de passe non identique"
            }).then(() => {});
            return;
        }

        if(formPasswordData.new_password.length < 8){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Le mot de passe doit avoir au minimun 8 caractères"
            }).then(() => {});
            return;
        }

        swal.fire({
            title: 'Continuer ?',
            text: "Vous êtes sur le point de modifier le mot de passe",
            icon: 'warning',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        })
            .then((rp) => {
                if(!rp.isConfirmed) {
                    return;
                }

                showLoader();

                apiUser.updateUserPassword(formPasswordData.old_password, formPasswordData.new_password)
                    .then((data) => {
                        handleClose();

                        swal.fire({
                            icon: 'success',
                            title: "",
                            text: "L'opération à été effectuée avec succès"
                        }).then(() => {});

                    })
                    .catch((err) => {

                        if (err.response){
                            swal.fire({
                                icon: 'error',
                                title: 'Erreur !!',
                                text: err.response.data.message
                            }).then(() => {});
                            return;
                        }

                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: 'Impossible de traiter la requête'
                        }).then(() => {});

                    })
                    .finally(() => {
                        hideLoader();
                    })
            })
    };


    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle> Changement de mot de passe </DialogTitle>
                <DialogContent>

                    <DialogContentText sx={{mb: 4}}>
                    </DialogContentText>

                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            label="Ancien Mot de Passe"
                            name="old_password"
                            value={formPasswordData.old_password}
                            onChange={handleFormPasswordFieldChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <TextField
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            label="Nouveau Mot de Passe"
                            name="new_password"
                            value={formPasswordData.new_password}
                            onChange={handleFormPasswordFieldChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <TextField
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            label="Confirmer le Mot de Passe"
                            name="new_password2"
                            value={formPasswordData.new_password2}
                            onChange={handleFormPasswordFieldChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClose}>Annuler</Button>
                    <Button variant="contained" color="success" onClick={handleFormPasswordSubmit}>Valider</Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}


CustomerPasswordChangeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CustomerPasswordChangeDialog;
