import React, {useCallback} from 'react';
import Page from '../../../components/Page';
import {
    Backdrop, Button, Chip,
    CircularProgress,
    Container, Stack, Tooltip,
    Typography
} from "@mui/material";
import {useSwal} from "../../../hooks/swal";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import * as ApiCommissionRegistering from "../../../api/commission_registering";
import * as ApiSubscription from "../../../api/subscription";
import * as ppUtils from '../../../libs/utils'
import {DataGrid, frFR, GridToolbar} from "@mui/x-data-grid";
import Iconify from "../../../components/Iconify";
import CommissionRegisteringDialogBulk from './CommissionRegisteringDialogBulk';
import CommissionRegisteringDialogUniq from './CommissionRegisteringDialogUniq';

const Commission = () => {

    const swal = useSwal();
    const navigate = useNavigate();

    const [isDialogCommissionRegisteringBulkOpen, setIsDialogCommissionRegisteringBulkOpen] = useState(false);
    const [isDialogCommissionRegisteringUniqOpen, setIsDialogCommissionRegisteringUniqOpen] = useState(false);
    const [isLoaderShow, setIsLoaderShow] = useState(false);
    const [commissionRegisteringData, setCommissionRegisteringData] = useState([]);
    const [subscriptionListData, setSubscriptionListData] = useState([]);

    const tableColumns = [
        {
            field: '_id',
            headerName: 'Action',
            renderCell: (params) => (
                <>
                    <Button 
                        href={`${process.env.REACT_APP_BACKEND_URL}/reports/commission-payment/${params.row._id}`} 
                        size="small" 
                        variant="contained"
                        target='_blank'
                    >
                        Fiche
                    </Button>
                    <Button 
                        href={`${process.env.REACT_APP_BACKEND_URL}/reports/commission-payment/${params.row._id}/_download`} 
                        size="small" variant="contained"
                        sx={{ml: "4px"}}
                        target='_blank'
                    >
                        Tel Fiche
                    </Button>
                </>
            ),
            width: 200
        },
        {
            field: 'periode',
            headerName: 'Periode',
            valueGetter: (params) => (`${ppUtils.formatDatePeriode(params.row.periode)}`),
            width: 150
        },
        {
            field: 'customer.name',
            headerName: 'Nom',
            valueGetter: (params) => (`${params.row.customer.last_name} ${params.row.customer.first_name}`),
            width: 250
        },
        {
            field: 'customer.matricule',
            headerName: 'Matricule',
            valueGetter: (params) => (`${params.row.customer.matricule}`),
            width: 250
        },
        {
            field: 'commission_amount',
            headerName: 'Commission Mensuel',
            valueGetter: (params) => (`${params.row.commission_amount} FCFA`),
            width: 250
        },
        {
            field: 'borrowed_amount_deducted',
            headerName: 'Déduction Montant Emprunte',
            valueGetter: (params) => (`${params.row.borrowed_amount_deducted} FCFA`),
            width: 250
        },
        {
            field: 'customer_amount_received',
            headerName: 'Montant Reçu',
            valueGetter: (params) => (`${params.row.customer_amount_received} FCFA`),
            width: 250
        },
        {
            field: 'payment_type',
            headerName: 'Type Paiement',
            valueGetter: (params) => (`${params.row.payment_type}`),
            width: 250
        },
        {
            field: 'payment_proof',
            headerName: 'Preuve Paiement',
            valueGetter: (params) => (`${params.row.payment_proof}`),
            width: 250
        },
        {
            field: 'admin.name',
            headerName: 'Agent',
            valueGetter: (params) => (`${params.row.admin.last_name} ${params.row.admin.first_name}`),
            width: 250
        },
        {
            field: 'added_at',
            headerName: 'Créée le',
            valueGetter: (params) => (`${ppUtils.formatDate(params.row.added_at)}`),
            width: 150
        },
    ];

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const handleDialogCommissionRegisteringBulkClose = useCallback(() => {
        setIsDialogCommissionRegisteringBulkOpen(false);
        fetchLastCommissionRegisteringBulk();
    }, []);

    const handleButtonAddCommissionRegisteringBulkClick = useCallback(() => {
        setIsDialogCommissionRegisteringBulkOpen(true);
    }, []);

    const handleDialogCommissionRegisteringUniqClose = useCallback(() => {
        fetchLastCommissionRegisteringBulk();
        setIsDialogCommissionRegisteringUniqOpen(false);
    }, []);

    const handleButtonAddCommissionRegisteringUniqClick = useCallback(() => {
        setIsDialogCommissionRegisteringUniqOpen(true);
    }, []);

    const fetchLastCommissionRegisteringBulk = async () => {
        showLoader();

        try{

            const rq = await Promise.all([
                ApiCommissionRegistering.getLastCommissionRegisteringBulk(),
                ApiSubscription.findAllFullSubscription()
            ]);

            setCommissionRegisteringData(rq[0]);
            setSubscriptionListData(rq[1]);
            hideLoader();
        }
        catch (err){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Une erreur s'est produite"
            }).then(() => {
                navigate(-1, {replace: true})
            });
            hideLoader();

        }
    };

    useEffect(() => {
        fetchLastCommissionRegisteringBulk();
    }, []);

    return (
        <Page title="Backoffice | Commission">
            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    COMMISSION
                </Typography>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>

                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="mdi:refresh" />}
                        onClick={fetchLastCommissionRegisteringBulk}
                    >
                        Actualisé
                    </Button>

                    <Button
                        variant="contained"
                        //component={RouterLink}
                        to="#"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={handleButtonAddCommissionRegisteringUniqClick}
                        sx={{ml: 2}}
                    >
                        Action Unique
                    </Button>

                    {/* <Button
                        variant="contained"
                        //component={RouterLink}
                        to="#"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={handleButtonAddCommissionRegisteringBulkClick}
                        sx={{ml: 2}}
                    >
                        Action Groupée
                    </Button> */}
                </Stack>

                <Typography variant="h6" sx={{ mb: 5 }}>
                    Commissions
                </Typography>

                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row._id}
                        columns={tableColumns}
                        rows={commissionRegisteringData}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        disableSelectionOnClick
                    />
                </div>

            </Container>

            <CommissionRegisteringDialogBulk
                open={isDialogCommissionRegisteringBulkOpen}
                onClose={handleDialogCommissionRegisteringBulkClose}
            />

            <CommissionRegisteringDialogUniq
                open={isDialogCommissionRegisteringUniqOpen}
                onClose={handleDialogCommissionRegisteringUniqClose}
                subscriptionList={subscriptionListData}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Page>
    );
};

export default Commission;

