import React from "react";
import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from "react-redux";
// material
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';


// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5, 0)
    }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
    const auth = useSelector(({auth}) => auth);

    if (auth?.isAuthenticated){
        return <Navigate to="/backoffice/dashboard" replace={true} />;
    }

    return (
        <>
            <HeaderStyle>
                <Logo />
            </HeaderStyle>
            <Outlet />
        </>
    );
}
