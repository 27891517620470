import {callApi} from "./_axios";

export const getAppStat = async () => {
    const resp = await callApi('/common/app-stats', 'get', {});
    return resp.data;
};

export const getTaxiOffer = async () => {
    const resp = await callApi('/common/taxi-offer', 'get', {});
    return resp.data;
};

export const getLastAdminAction = async () => {
    const resp = await callApi('/common/last-admin-action', 'get', {});
    return resp.data;
};

export const getCountry= async () => {
    const resp = await callApi('/common/country', 'get', {});
    return resp.data;
};
