import { configureStore } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './features/auth';

const persistConfig = {
    key: 'auth',
    version: 1,
    storage,
}

const store = configureStore({
    devTools: true,
    reducer: {
        auth: persistReducer(persistConfig, authReducer)
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]}
        });
    }
});

let persistor = persistStore(store)

export {persistor, store};
