import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Page from '../../../components/Page';
import {
    Backdrop, Box,
    Button, Chip, CircularProgress,
    Container,
    Grid, Paper,
    Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import {useSwal} from "../../../hooks/swal";
import {useEffect, useState} from "react";
import * as apiCustomer from "../../../api/customer";
import * as ppUtils from "../../../libs/utils";


const CustomerSheet = () => {

    let {customerId} = useParams();

    const swal = useSwal();
    const navigate = useNavigate();

    const [customerData, setCustomerData] = useState(null);
    const [isLoaderShow, setIsLoaderShow] = useState(false);
    const [hasLoadingError, setHasLoadingError] = useState(false);

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    useEffect(() => {

        showLoader();

        apiCustomer.findOneCustomer(customerId)
            .then(data => {
                setHasLoadingError(false);
                setCustomerData(data);
            })
            .catch(err => {
                setHasLoadingError(true);
            })
            .finally(() => {
                hideLoader();
            })
    }, []);

    if (hasLoadingError) {
        return (
            <>
                <Page title="Backoffice: Fiche Client">
                    <Container>
                        Une Erreur c'est produite
                    </Container>
                </Page>
            </>
        );
    }

    if (!customerData) {
        return (
            <>
            </>
        );
    }

    return (
        <Page title="Backoffice | Fiche Client">
            <Container sx={{mt: 10}}>
                <Typography variant="h3" sx={{mt: 10, textAlign: "center"}}>
                    FICHE CLIENT
                </Typography>


                <Typography variant="h4" sx={{mt: 5, mb: 2}}>GÉNÉRALITÉ</Typography>

                <Paper sx={{p: 4}}>
                    <Grid container spacing={2}>
                        <Grid item md={4}>
                            <Typography variant="subtitle1">Nom & Prenom Client :</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.last_name} {customerData.first_name}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Matricule</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.matricule}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Compte comfirmé </Typography>
                        </Grid>
                        <Grid item md={8}>
                            {!!customerData.account_confirmed_at ? <Chip label={`OUI - le ${ppUtils.formatDateTime(customerData.account_confirmed_at)}`} /> : <Chip label="NON" />}
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Compte Bloqué</Typography>
                        </Grid>
                        <Grid item md={8}>
                            {!!customerData.locked_at ? <Chip label={`OUI - le ${ppUtils.formatDateTime(customerData.locked_at)}`} /> : <Chip label="NON" />}
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Pays</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.country_id.name}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Ville</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.city}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">State</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.state}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Username</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.username}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Email</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.email}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Contact</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.phone_number_prefix} {customerData.phone_number}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Adresse</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.address}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Zip Code</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.zip_code}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">RIB Bancaire</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="body1">{customerData.bank_rib}</Typography>
                        </Grid>

                        <Grid item md={4}>
                            <Typography variant="subtitle1">Inscription en Agence</Typography>
                        </Grid>
                        <Grid item md={8}>
                            {!!customerData.user_admin_id ? <Chip label={`OUI - par ${customerData.user_admin_id.last_name} ${customerData.user_admin_id.first_name}`} /> : <Chip label="NON" />}
                        </Grid>

                    </Grid>
                </Paper>

                <Typography variant="h4" sx={{mt: 5, mb: 2}}>SOUSCRIPTIONS</Typography>

                <Paper sx={{p: 4}}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell align="left">Reference</TableCell>
                                    <TableCell align="left">Type D'offre</TableCell>
                                    <TableCell align="left">Date Souscription</TableCell>
                                    <TableCell align="left">Paiement Soldé</TableCell>
                                    <TableCell align="left">Souscription en Agence</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {customerData.subscriptions.length > 0 ?
                                    (
                                        <>
                                            {customerData.subscriptions.map((item) => {
                                                return (
                                                    <TableRow
                                                        key={item._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <Button href={`/backoffice/subscription-sheet/${item._id}`} target="_blank" size="small">voir la fiche</Button>
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {ppUtils.formatDate(item.reference)}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {item.my_taxi_offer_id.label}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {ppUtils.formatDateTime(item.subscribed_at)}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {!!item.offer_cost_payment_completed_at ? <Chip label={`OUI - le ${ppUtils.formatDateTime(item.offer_cost_payment_completed_at)}`} /> : <Chip label="NON" />}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <Chip label={!item.is_online_subscription ? `OUI - par ${item.user_admin_id.last_name} ${item.user_admin_id.first_name}` : "NON"} />
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            })}
                                        </>
                                    ) :
                                    (
                                        <>
                                            <TableRow>
                                                <TableCell component="th" scope="row" colSpan={4}>
                                                    aucune information disponible
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )
                                }
                            </TableBody >
                        </Table>
                    </TableContainer>
                </Paper>

                <Box componenr="p" sx={{mb: 10}}>
                </Box>

            </Container>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1}}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </Page>
    );
};

export default CustomerSheet;
