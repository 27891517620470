import * as React from 'react';
import Page from '../../../components/Page';
import {
    Backdrop,
    Chip, CircularProgress,
    Container,
    Typography
} from "@mui/material";
import {useSwal} from "../../../hooks/swal";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import * as ApiCommon from "../../../api/common";
import * as ppUtils from '../../../libs/utils'
import {DataGrid, frFR, GridToolbar} from "@mui/x-data-grid";

const OffreMonTaxi = () => {

    const swal = useSwal();
    const navigate = useNavigate();

    const [isLoaderShow, setIsLoaderShow] = useState(false);
    const [taxiOfferData, setTaxiOfferData] = useState([]);

    const tableColumns = [
        {field: 'label', headerName: 'Nom', minWidth: 50},
        {field: 'offer_version', headerName: 'Version', minWidth: 50},
        {field: 'cost', headerName: 'Cout', width: 300},
        {field: 'local_max_echeance_number', headerName: 'Nombre Max Echeance Agence', width: 300},
        {field: 'online_max_echeance_number', headerName: 'Nombre Max Echeance Online', width: 300},
        {field: 'online_min_echeance_number', headerName: 'Nombre Min Echeance Online', width: 300},
        {field: 'monthly_commission_amount', headerName: 'Montant Commission Mensuelle (FCFA)', width: 300},
        {field: 'monthly_commission_number', headerName: 'Nombre Commission Mensuelle', width: 300},
        {field: 'illustration_img_url', headerName: 'Url Img', minWidth: 300},
        {
            field: 'added_at',
            headerName: 'Ajouté le',
            valueGetter: (params) => (`${ppUtils.formatDate(params.row.added_at)}`),
            width: 250
        },
        {
            field: 'updated_at',
            headerName: 'Dernière Modification',
            valueGetter: (params) => (`${ppUtils.formatDate(params.row.updated_at)}`),
            width: 200
        }
    ];

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const fetchCustomer = () => {
        showLoader();

        ApiCommon.getTaxiOffer()
            .then((data) => {
                setTaxiOfferData(data);
            })
            .catch((err) => {
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Une erreur s'est produite"
                }).then(() => {
                    navigate(-1, {replace: true})
                });
            })
            .finally(() => {
                hideLoader();
            })
    };

    useEffect(() => {
        fetchCustomer();
    }, []);

    return (
        <Page title="Backoffice | Offre Mon Taxi">
            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    OFFRE MON TAXI
                </Typography>


                <Typography variant="h6" sx={{ mb: 5 }}>
                    Pourcentage paiement en ligne : <Chip label="4.5 %" />
                </Typography>

                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row._id}
                        columns={tableColumns}
                        rows={taxiOfferData}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        disableSelectionOnClick
                        hideFooterPagination={true}
                    />
                </div>

            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Page>
    );
};

export default OffreMonTaxi;
