import React, {useCallback} from 'react';
import Page from '../../../components/Page';
import {
    Backdrop, Button, Chip, Box, Tabs, Tab, 
    CircularProgress,
    Container, Stack, Tooltip,
    Typography
} from "@mui/material";
import { TabPanel, TabList, TabContext} from '@mui/lab';
import {useSwal} from "../../../hooks/swal";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import * as ApiLoan from "../../../api/loan";
import * as ApiSubscription from "../../../api/subscription";
import * as ppUtils from '../../../libs/utils'
import {DataGrid, frFR, GridToolbar} from "@mui/x-data-grid";
import Iconify from "../../../components/Iconify";
import LoanDialog from './LoanDialog';

const Loan = () => {

    const swal = useSwal();
    const navigate = useNavigate();

    const [isDialogLoanOpen, setIsDialogLoanOpen] = useState(false);
    const [isLoaderShow, setIsLoaderShow] = useState(false);
    const [selectedTab, setSelectedTab] = useState("1");
    const [loanOkData, setLoanOkData] = useState([]);
    const [loanNoOkData, setLoanNoOkData] = useState([]);
    const [subscriptionListData, setSubscriptionListData] = useState([]);

    const tableColumnsLoanNoOk = [
        {
            field: '_id',
            headerName: 'Action',
            renderCell: (params) => (
                <>
                    <Button 
                        size="small" 
                        variant="contained"
                        onClick={() => handleButtonDelLoanClick(params.row._id)}
                    >
                        Supprimer
                    </Button>
                </>
            ),
            width: 200
        },
        {
            field: 'reference',
            headerName: 'Reference Prêt',
            valueGetter: (params) => (`${params.row.reference}`),
            width: 150
        },
        {
            field: 'customer.name',
            headerName: 'Nom Client',
            valueGetter: (params) => (`${params.row.user_customer_id.last_name} ${params.row.user_customer_id.first_name}`),
            width: 250
        },
        {
            field: 'customer.matricule',
            headerName: 'Matricule Client',
            valueGetter: (params) => (`${params.row.user_customer_id.matricule}`),
            width: 250
        },
        {
            field: 'subscription_id',
            headerName: 'Reference Souscription',
            valueGetter: (params) => (`${params.row.subscription_id.reference_code}`),
            width: 250
        },
        {
            field: 'borrowed_amount',
            headerName: 'Montant Prêt',
            valueGetter: (params) => (`${params.row.borrowed_amount} FCFA`),
            width: 250
        },
        {
            field: 'amount_paid',
            headerName: 'Montant Remboursé',
            valueGetter: (params) => (`${params.row.amount_paid} FCFA`),
            width: 250
        },
        {
            field: 'is_ok',
            headerName: 'Remboursement Terminé ?',
            valueGetter: (params) => (`${params.row.is_ok ? "OUI" : "NON"}`),
            width: 250
        },
        {
            field: 'comment',
            headerName: 'Commentaire',
            valueGetter: (params) => (`${params.row.comment}`),
            width: 250
        },
        {
            field: 'user_admin_id',
            headerName: 'Agent',
            valueGetter: (params) => (`${params.row.user_admin_id.last_name} ${params.row.user_admin_id.first_name}`),
            width: 250
        },
        {
            field: 'added_at',
            headerName: 'Créée le',
            valueGetter: (params) => (`${ppUtils.formatDate(params.row.added_at)}`),
            width: 150
        },
    ];

    const tableColumnsLoanOk = [
        {
            field: 'reference',
            headerName: 'Reference Prêt',
            valueGetter: (params) => (`${params.row.reference}`),
            width: 150
        },
        {
            field: 'customer.name',
            headerName: 'Nom Client',
            valueGetter: (params) => (`${params.row.user_customer_id.last_name} ${params.row.user_customer_id.first_name}`),
            width: 250
        },
        {
            field: 'customer.matricule',
            headerName: 'Matricule Client',
            valueGetter: (params) => (`${params.row.user_customer_id.matricule}`),
            width: 250
        },
        {
            field: 'subscription_id',
            headerName: 'Reference Souscription',
            valueGetter: (params) => (`${params.row.subscription_id.reference_code}`),
            width: 250
        },
        {
            field: 'borrowed_amount',
            headerName: 'Montant Prêt',
            valueGetter: (params) => (`${params.row.borrowed_amount} FCFA`),
            width: 250
        },
        {
            field: 'amount_paid',
            headerName: 'Montant Remboursé',
            valueGetter: (params) => (`${params.row.amount_paid} FCFA`),
            width: 250
        },
        {
            field: 'is_ok',
            headerName: 'Remboursement Terminé ?',
            valueGetter: (params) => (`${params.row.is_ok ? "OUI" : "NON"}`),
            width: 250
        },
        {
            field: 'comment',
            headerName: 'Commentaire',
            valueGetter: (params) => (`${params.row.comment}`),
            width: 250
        },
        {
            field: 'user_admin_id',
            headerName: 'Agent',
            valueGetter: (params) => (`${params.row.user_admin_id.last_name} ${params.row.user_admin_id.first_name}`),
            width: 250
        },
        {
            field: 'added_at',
            headerName: 'Créée le',
            valueGetter: (params) => (`${ppUtils.formatDate(params.row.added_at)}`),
            width: 150
        },
    ];
    

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const handleDialogLoanClose = useCallback(() => {
        fetchLoanData();
        setIsDialogLoanOpen(false);
    }, []);

    const handleButtonAddLoanClick = useCallback(() => {
        setIsDialogLoanOpen(true);
    }, []);

    const handleButtonDelLoanClick = async (loanId) => {

        swal.fire({
            title: 'Continuer ?',
            text: "Vous êtes sur le point de ",
            icon: 'warning',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        })
        .then((rp) => {
            if(!rp.isConfirmed) {
                return;
            }
        
            showLoader();
            ApiLoan.delLoan(loanId)
                .then((data) => {
                    swal.fire({
                        icon: 'success',
                        title: "",
                        text: "L'opération à été effectuée avec succès"
                    }).then(() => {});
                })
                .catch((err) => {
                    if (err.response){
                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: err.response.data.message
                        }).then(() => {});
                        return;
                    }

                    swal.fire({
                        icon: 'error',
                        title: 'Erreur !!',
                        text: 'Impossible de traiter la requête'
                    }).then(() => {});
                })
                .finally(() => {
                    hideLoader();
                    fetchLoanData();
                })
            });
    };

    const fetchLoanData = async () => {
        showLoader();

        try{
            const rq = await Promise.all([
                ApiLoan.findAllLoanOk(),
                ApiLoan.findAllLoanNoOk(),
                ApiSubscription.findAllFullSubscription()
            ]);

            setLoanOkData(rq[0]);
            setLoanNoOkData(rq[1]);
            setSubscriptionListData(rq[2]);
            hideLoader();
        }
        catch (err){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Une erreur s'est produite"
            }).then(() => {
                navigate(-1, {replace: true})
            });
            hideLoader();
        }
    };

    useEffect(() => {
        fetchLoanData();
    }, []);

    return (
        <Page title="Backoffice | Prêt">
            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Prêt
                </Typography>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>

                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="mdi:refresh" />}
                        onClick={fetchLoanData}
                    >
                        Actualisé
                    </Button>

                    <Button
                        variant="contained"
                        //component={RouterLink}
                        to="#"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={handleButtonAddLoanClick}
                        sx={{ml: 2}}
                    >
                        Créer Prêt
                    </Button>
                </Stack>

                <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e, val) => setSelectedTab(val)} aria-label="lab API tabs example">
                            <Tab label="PRÊT NON REMBOURSÉ" value="1" />
                            <Tab label="PRÊT REMBOURSÉ"  value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Typography variant="h6" sx={{ mb: 5 }}>
                            Prêt non Remboursé
                        </Typography>

                        <div style={{ height: 500, width: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row._id}
                                columns={tableColumnsLoanNoOk}
                                rows={loanNoOkData}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                disableSelectionOnClick
                            />
                        </div>
                    </TabPanel>

                    <TabPanel value="2">
                        <Typography variant="h6" sx={{ mb: 5 }}>
                            Prêt Remboursé
                        </Typography>

                        <div style={{ height: 500, width: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row._id}
                                columns={tableColumnsLoanOk}
                                rows={loanOkData}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                disableSelectionOnClick
                            />
                        </div>
                    </TabPanel>
                </TabContext>

            </Container>


            <LoanDialog
                open={isDialogLoanOpen}
                onClose={handleDialogLoanClose}
                subscriptionList={subscriptionListData}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Page>
    );
};

export default Loan;

