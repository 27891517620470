import {callApi} from "./_axios";

export const getLoginUser = async () => {
    const resp = await callApi('/user', 'get', {});
    return resp.data;
};

export const updateUserProfile = async (address, zipCode, bankRib) => {
    const resp = await callApi('/user/update-profile', 'post', {address, zip_code: zipCode, bank_rib: bankRib});
    return resp.data;
};

export const updateUserPassword = async (oldPassword, newPassword) => {
    const resp = await callApi('/user/update-password', 'post', {old_password: oldPassword, new_password: newPassword});
    return resp.data;
};
