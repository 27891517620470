import {callApi} from './_axios';

export const findAllLoanOk = async () => {
    const res = await callApi("/loans/_ok", "get", {});
    return res.data;
}

export const findAllLoanNoOk = async () => {
    const res = await callApi("/loans/_no_ok", "get", {});
    return res.data;
}

export const addLoan = async (data) => {
    const res = await callApi("/loans", "post", data);
    return res.data;
}

export const delLoan = async (loanId) => {
    const res = await callApi(`/loans/${loanId}`, "delete");
    return res.data;
}


