import * as yup from 'yup';
import moment from 'moment';

export const normalizeAmount = (amount) => {
    /*  the amount must be a multiple of 5  */
    amount = parseInt(amount);
    if ((amount % 5) === 0){
        return amount;
    }

    const m5 = Math.floor(amount / 5);

    return (m5 + 1) * 5;
};

export const formatDate = (date) => {
    return moment(date).utc().format("DD/MM/YYYY");
}

export const formatDatePeriode = (date) => {
    return moment(date).utc().format("MM/YYYY");
}

export const formatDateTime = (date) => {
    return moment(date).utc().format("DD/MM/YYYY à HH:mm");
}


