import React, {useEffect, useMemo, useState} from "react";
import PropTypes from 'prop-types';
import {
    Button,
    Stack,
    Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, Chip,
    TextField, CircularProgress, Backdrop, Paper, Alert, Divider
} from "@mui/material";
import {useSwal} from '../../../hooks/swal';
import * as ApiSubscription from './../../../api/subscription';


const EcheancePaymentDialog = ({open, onClose, subscriptionItem}) => {

    const [isLoaderShow, setIsLoaderShow] = useState(false);

    const swal = useSwal();

    const handleClose = () => {
        onClose();
    };

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const handleEcheancePaymentButtonClick = (e) => {
        swal.fire({
            title: 'Continuer ?',
            text: "Vous êtes sur le point d'effectuer le paiement d'une echeance",
            icon: 'warning',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        })
            .then((rp) => {
                if(!rp.isConfirmed) {
                    return;
                }

                showLoader();

                ApiSubscription.addSubscriptionEcheancePayment(subscriptionItem._id)
                    .then((data) => {
                        handleClose();
                        swal.fire({
                            icon: 'success',
                            title: "",
                            html:
                                "L'opération à été effectuée avec succès <br /> <br /> <br />" +
                                `Numero Reçu de paiement: ${data.payment_receipt_number}`,
                        }).then(() => {});
                    })
                    .catch((err) => {

                        if (err.response){
                            swal.fire({
                                icon: 'error',
                                title: 'Erreur !!',
                                text: err.response.data.message
                            }).then(() => {});
                            return;
                        }

                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: 'Impossible de traiter la requête'
                        }).then(() => {});

                    })
                    .finally(() => {
                        hideLoader();
                    })
            })
    }

    if(!subscriptionItem){
        return (
            <>

            </>
        )
    }


    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>PAIEMENT D'ECHEANCE</DialogTitle>
                <DialogContent>

                    <DialogContentText sx={{mb: 4}}>
                    </DialogContentText>

                        <Stack spacing={3}>

                            <TextField
                                fullWidth
                                type="text"
                                label="REFERENCE DE LA SOUSCRIPTION"
                                InputProps={{
                                    readOnly: true
                                }}
                                value={subscriptionItem.reference_code}
                            />

                            <TextField
                                fullWidth
                                type="text"
                                label="OFFRE MON TAXI"
                                InputProps={{
                                    readOnly: true
                                }}
                                value={subscriptionItem.my_taxi_offer_id.label}
                            />

                            <TextField
                                fullWidth
                                type="text"
                                label="NOM DU CLIENT"
                                InputProps={{
                                    readOnly: true
                                }}
                                value={`${subscriptionItem.user_customer_id.first_name} ${subscriptionItem.user_customer_id.last_name}`}
                            />

                            <TextField
                                fullWidth
                                type="text"
                                label="MATRICULE DU CLIENT"
                                InputProps={{
                                    readOnly: true
                                }}
                                value={`${subscriptionItem.user_customer_id.matricule}`}
                            />

                            <Divider variant="inset"><Chip label="RECAPUTILATIF" /></Divider>

                            <Paper elevation={4} sx={{p: 4, bgcolor: "#d8d8e0"}}>
                                <div>
                                    <strong>COUT DE L'OFFRE (FCFA) : </strong> <strong style={{float: "right"}}>{ subscriptionItem.my_taxi_offer_id.cost}</strong>
                                </div>

                                <div>
                                    <strong>ECHEANCE PAYEE : </strong>
                                    <Chip
                                        sx={{float: "right"}}
                                        size="small"
                                        label={`${subscriptionItem.echeance_payment_status.paid_echeance_count	} / ${subscriptionItem.echeance_payment_status.number_echeance_chosen}`}
                                        color="primary"
                                    />
                                </div>

                                <div>
                                    <strong>MONTANT PAR D'ECHEANCE (FCFA) : </strong> <strong style={{float: "right"}}> {subscriptionItem.echeance_payment_status.amount_by_echeance_to_pay}</strong>
                                </div>


                            </Paper>

                            <Alert severity="warning">
                                    Le paiement d'une echeance de paiment est irreversible.
                            </Alert>

                        </Stack>

                </DialogContent>
                <DialogActions>

                    <Button variant="contained" color="error" onClick={handleClose}>Annuler</Button>

                    <Button variant="contained" color="success" onClick={handleEcheancePaymentButtonClick}>Valider le Paiement</Button>

                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

EcheancePaymentDialog.defaultProps = {
    taxiOfferData: []
};

EcheancePaymentDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    subscriptionItem: PropTypes.object,
};

export default EcheancePaymentDialog;
