import * as React from 'react';
import { useParams } from "react-router-dom";
import Page from '../../../components/Page';
import {
    Backdrop, Paper, Chip,
    Button, CircularProgress,
    Container,
    Grid,
    Stack, Box,
    Typography,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
import {useEffect, useState} from "react";
import * as apiSubscription from "../../../api/subscription";
import * as ppUtils from '../../../libs/utils';

const SubscriptionSheet = () => {

        let {subscriptionId} = useParams();

        const [subscriptionData, setSubscriptionData] = useState(null);
        const [isLoaderShow, setIsLoaderShow] = useState(false);
        const [hasLoadingError, setHasLoadingError] = useState(false);

        const showLoader = () => {
            setIsLoaderShow(true);
        }

        const hideLoader = () => {
            setIsLoaderShow(false);
        }

        useEffect(() => {

            showLoader();

            apiSubscription.findOneSubscription(subscriptionId)
                .then(data => {
                    setHasLoadingError(false);
                    setSubscriptionData(data);
                })
                .catch(err => {
                    setHasLoadingError(true);
                })
                .finally(() => {
                    hideLoader();
                })
        }, []);

        if (hasLoadingError) {
            return (
                <>
                    <Page title="Backoffice | Fiche Souscription">
                        <Container>
                            Une Erreur c'est produite
                        </Container>
                    </Page>
                </>
            );
        }

        if (!subscriptionData) {
            return (
                <>
                </>
            );
        }

        return (
            <Page title="Backoffice: Fiche Souscription">
                <Container>
                    <Typography variant="h3" sx={{mt: 10, textAlign: "center"}}>
                        FICHE SOUSCRIPTION
                    </Typography>


                    <Typography variant="h4" sx={{mt: 5, mb: 2}}>GÉNÉRALITÉ</Typography>

                    <Paper sx={{p: 4}}>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Typography variant="subtitle1">Réference :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant="body1">{subscriptionData.reference_code}</Typography>
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="subtitle1">Matricule du Client :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant="body1">{subscriptionData.user_customer_id.matricule}</Typography>
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="subtitle1">Nom & Prenom Client :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant="body1">{subscriptionData.user_customer_id.last_name} {subscriptionData.user_customer_id.first_name}</Typography>
                            </Grid>

                            <Grid item md={4}>
                            </Grid>
                            <Grid item md={8}>
                                <Button href={`/backoffice/customer-sheet/${subscriptionData.user_customer_id._id}`} target="_blank" size="small">voir la fiche client</Button>
                            </Grid>


                            <Grid item md={4}>
                                <Typography variant="subtitle1">Type d'Offre :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant="body1">{subscriptionData.my_taxi_offer_id.label}</Typography>
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="subtitle1">Coût de l'offre (FCFA) :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant="body1">{subscriptionData.subscribed_taxi_offer.cost}</Typography>
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="subtitle1">Date de la Souscription :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant="body1">{ppUtils.formatDate(subscriptionData.subscribed_at)}</Typography>
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="subtitle1">Paiement Par Echeance :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Chip label={subscriptionData.echeance_payment_status.is_actived ? "OUI" : "NON"} />
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="subtitle1">Paiement Soldé :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Chip label={subscriptionData.offer_cost_payment_completed_at ? `OUI - le ${ppUtils.formatDate(subscriptionData.offer_cost_payment_completed_at)}` : "NON"} />
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="subtitle1">Commission Mensuelle (FCFA) :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant="body1">{subscriptionData.subscribed_taxi_offer.monthly_commission_amount}</Typography>
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="subtitle1">Nombre de Commisions Mensuelle :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant="body1">{subscriptionData.subscribed_taxi_offer.monthly_commission_number}</Typography>
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="subtitle1">Souscription en Agence :</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Chip label={!subscriptionData.is_online_subscription ? `OUI - par ${subscriptionData.user_admin_id.last_name} ${subscriptionData.user_admin_id.first_name}` : "NON"} />
                            </Grid>

                            {(subscriptionData.offer_cost_payment_completed_at) && (
                                <>
                                    <Grid item md={4}>
                                        <Typography variant="subtitle1">Periode Debut Commission :</Typography>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body1">{ppUtils.formatDatePeriode(subscriptionData.commission_payment_start_date)}</Typography>
                                    </Grid>

                                    <Grid item md={4}>
                                        <Typography variant="subtitle1">Periode Fin Commission :</Typography>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body1">{ppUtils.formatDatePeriode(subscriptionData.commission_payment_end_date)}</Typography>
                                    </Grid>
                                </>
                            )}

                        </Grid>
                    </Paper>

                    {(subscriptionData.echeance_payment_status.is_actived ) && (
                        <>
                            <Typography variant="h4" sx={{mt: 5, mb: 2}}>PAIEMENT PAR ECHEANCE</Typography>

                            <Paper sx={{p: 4}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1">Nombre d'echeance :</Typography>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body1">{subscriptionData.echeance_payment_status.number_echeance_chosen}</Typography>
                                    </Grid>

                                    <Grid item md={4}>
                                        <Typography variant="subtitle1"> Nombre d'echeance Payée :</Typography>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body1">{subscriptionData.echeance_payment_status.paid_echeance_count}</Typography>
                                    </Grid>

                                    <Grid item md={4}>
                                        <Typography variant="subtitle1"> Nombre d'echeance Restant :</Typography>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body1">{subscriptionData.echeance_payment_status.number_echeance_chosen - subscriptionData.echeance_payment_status.paid_echeance_count}</Typography>
                                    </Grid>

                                    <Grid item md={4}>
                                        <Typography variant="subtitle1"> Montant par écheance (FCFA) :</Typography>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body1">{subscriptionData.echeance_payment_status.amount_by_echeance_to_pay}</Typography>
                                    </Grid>

                                </Grid>

                            </Paper>
                        </>
                    )}

                    <Typography variant="h4" sx={{mt: 5, mb: 2}}>HISTORIQUES DES PAIEMENT</Typography>

                    <Paper sx={{p: 4}}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>N° Reçu</TableCell>
                                        <TableCell align="left">Montant (FCFA)</TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Paiement en Agence ?</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                {subscriptionData.subscription_payments.length > 0 ?
                                    (
                                        <>
                                            {subscriptionData.subscription_payments.map((item) => {
                                                return (
                                                    <TableRow
                                                        key={item._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {item.receipt_number}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {item.offer_subscription_amount + item.online_payment_fees_amount}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {ppUtils.formatDateTime(item.added_at)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {!item.is_online_payment ? <Chip label={`OUI - par ${item.user_admin_id.last_name}  ${item.user_admin_id.first_name}`} /> : <Chip label="NON" />}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </>
                                    ) :
                                    (
                                        <>
                                            <TableRow>
                                                <TableCell component="th" scope="row" colSpan={4}>
                                                    aucune information disponible
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )
                                }
                                </TableBody >
                            </Table>
                        </TableContainer>
                    </Paper>


                    <Typography variant="h4" sx={{mt: 5, mb: 2}}>COMMISSIONS</Typography>

                    <Paper sx={{p: 4}}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Periode</TableCell>
                                        <TableCell align="left">Mode</TableCell>
                                        <TableCell align="left">Admin</TableCell>
                                        <TableCell align="left">Date de Paiement</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {subscriptionData.commission_payment_status.length > 0 ?
                                        (
                                            <>
                                                {subscriptionData.commission_payment_status.map((item) => {
                                                    return (
                                                        <TableRow
                                                            key={item._id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {ppUtils.formatDatePeriode(item.periode)}
                                                            </TableCell>

                                                            

                                                            <TableCell align="left">
                                                                <Chip label={item.payment_type} />
                                                            </TableCell>

                                                            <TableCell align="left">
                                                                {item.user_admin_id.last_name} {item.user_admin_id.first_name}
                                                            </TableCell>

                                                            <TableCell align="left">
                                                                {ppUtils.formatDateTime(item.added_at)}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </>
                                        ) :
                                        (
                                            <>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" colSpan={4}>
                                                        aucune information disponible
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    }
                                </TableBody >
                            </Table>
                        </TableContainer>
                    </Paper>

                    <Box componenr="p" sx={{mb: 10}}>
                    </Box>
                </Container>

                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1}}
                    open={isLoaderShow}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </Page>
        );
}

export default SubscriptionSheet;
