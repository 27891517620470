// material
import {Box, Grid, Container, Typography, useTheme, CircularProgress, Backdrop} from '@mui/material';
// components
import Page from '../../../components/Page';
import StatsCard from "./StatsCard";
import * as ApiCommon from "../../../api/common";
import React, {useEffect, useState} from "react";
import {useSwal} from "../../../hooks/swal";
import {useNavigate} from "react-router-dom";


export default function Dashboard() {

  const theme = useTheme();
  const swal = useSwal();
  const navigate = useNavigate();

  const [isLoaderShow, setIsLoaderShow] = useState(false);
  const [appStats, setAppStats] = useState({
    user_customer_count: 0,
    all_subscription_count: 0,
    subscription_payment_completed_count: 0,
    subscription_payment_incompleted_count: 0,
  });

  const showLoader = () => {
    setIsLoaderShow(true);
  }

  const hideLoader = () => {
    setIsLoaderShow(false);
  }

  const fetchCustomer = () => {
    showLoader();

    ApiCommon.getAppStat()
        .then((data) => {
          setAppStats({
            user_customer_count: data.user_customer_count,
            all_subscription_count: data.all_subscription_count,
            subscription_payment_completed_count: data.subscription_payment_completed_count,
            subscription_payment_incompleted_count: data.subscription_payment_incompleted_count,
          });
        })
        .catch((err) => {
          swal.fire({
            icon: 'error',
            title: 'Erreur !!',
            text: "Une erreur s'est produite"
          }).then(() => {
            navigate(-1, {replace: true})
          });
        })
        .finally(() => {
          hideLoader();
        })
  };

  useEffect(() => {
    fetchCustomer();
  }, []);


  return (
    <Page title="Backoffice | Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">DASHBOARD</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard label="Nombre Clients" count={appStats.user_customer_count} iconName="mdi:chart-arc" color={"#2980B9"}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard label="Nombre de Souscriptions" count={appStats.all_subscription_count} iconName="mdi:chart-arc" color={"#2980B9"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard label="Nombre de Souscriptions OK" count={appStats.subscription_payment_completed_count} iconName="mdi:chart-arc" color={"#2980B9"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard label="Nombre de Souscriptions NOK" count={appStats.subscription_payment_incompleted_count} iconName="mdi:chart-arc" color={"#2980B9"} />
          </Grid>
        </Grid>
      </Container>

      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
          open={isLoaderShow}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
}
