import React from "react";
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import BackOfficeLayout from './layouts/Backoffice';
import AuthLayout from './layouts/AuthLayout';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import BackofficeNoSidebarLayout from './layouts/BackofficeNoSidebarLayout';
//
import Login from './pages/Login';
import Dashboard from './pages/backoffice/Dashboard';
import Customer from './pages/backoffice/Customer';
import Subscription from './pages/backoffice/Subscription';
import Profile from './pages/backoffice/Profile';
import Report from './pages/backoffice/Report';
import Commission from './pages/backoffice/Commission';
import AdminActionHistory from './pages/backoffice/AdminActionHistory';
import MyTaxiOffer from './pages/backoffice/MyTaxiOffer';
import CustomerSheet from './pages/backoffice/CustomerSheet';
import SubscriptionSheet from './pages/backoffice/SubscriptionSheet';
import Loan from './pages/backoffice/Loan';
import NotFound from './pages/Page404';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/backoffice',
      element: <BackOfficeLayout />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'customer', element: <Customer /> },
        { path: 'subscription', element: <Subscription /> },
        { path: 'profile', element: <Profile /> },
        { path: 'mytaxi-offer', element: <MyTaxiOffer /> },
        { path: 'admin-action-history', element: <AdminActionHistory /> },
        { path: 'commission', element: <Commission /> },
        { path: 'report', element: <Report /> },
        { path: 'loan', element: <Loan /> },
      ]
    },
    {
      path: '/backoffice',
      element: <BackofficeNoSidebarLayout />,
      children: [
        { path: 'customer-sheet/:customerId', element: <CustomerSheet /> },
        { path: 'subscription-sheet/:subscriptionId', element: <SubscriptionSheet /> },
      ]
    },
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        { path: '', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/auth/login" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
