import {callApi} from './_axios';

export const findAllSubscription = async () => {
    const res = await callApi("/subscription", "get", {});
    return res.data;
}

export const findAllFullSubscription = async () => {
    const res = await callApi("/subscription/_full", "get", {});
    return res.data;
}

export const findOneSubscription = async (subscriptionId) => {
    const res = await callApi("/subscription/" + String(subscriptionId), "get", {});
    return res.data;
}

export const addSubscription = async (userData) => {
    const res = await callApi("/subscription", "post", userData);
    return res.data;
}

export const addSubscriptionEcheancePayment = async (subscriptionId) => {
    const res = await callApi("/subscription/subscription-echeance-payment", "post", {subscription_id: subscriptionId});
    return res.data;
}


