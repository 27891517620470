import axios from "axios";
import {store} from "../store";
import {signOut} from '../features/auth'

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const {status, data} = error.response;
    const {auth} = store.getState();

    if (status === 401 || status === 403){

        if (auth?.token){
            store.dispatch(signOut());
            window.location.reload();
        }
    }

    return Promise.reject(error);
});

export const callApiWithFormData = (endPoint, method, formData) => {
    const {auth} = store.getState();
    let headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'multipart/form-data'
    };

    if (auth?.token){
        headers['Authorization'] = `Bearer ${ auth.token}`;
    }

    return axios({
        baseURL: `${process.env.REACT_APP_API_URL}${endPoint}`,
        method,
        data: formData,
        headers: headers,
        //timeout: 10000
    });
};

export const callApi = (endPoint, method, data) => {
    const {auth} = store.getState();
    let headers = {'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json'};

    if (auth?.token){
        headers['Authorization'] = `Bearer ${ auth.token}`;
    }

    return axios({
        baseURL: `${process.env.REACT_APP_API_URL}${endPoint}`,
        method,
        data,
        headers: headers,
        //timeout: 10000
    });
};
