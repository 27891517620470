import * as React from 'react';

import Page from '../../../components/Page';
import {
    Button,
    Container,
    Grid,
    Stack,
    Typography
} from "@mui/material";


const Report = () => {
    return (
        <Page title="Backoffice | Rapports">
            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    RAPPORT
                </Typography>

            </Container>
        </Page>
    );
};

export default Report;
