import React from "react";
import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import {useDispatch} from "react-redux";
import {signIn as signInAction} from "../../features/auth";
import * as apiAuth from '../../api/auth';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../components/Iconify';
import {useSwal} from '../../hooks/swal';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const swal = useSwal();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Le nom d'utilisateur est obligatoire"),
    password: Yup.string().required('Le mot de passe est obligatoire')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (formData) => {

      swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
      swal.showLoading();

      try {
        const resData = await apiAuth.signIn(formData.username, formData.password);
        dispatch(signInAction({token: resData.token, user: resData.user}));
        navigate("/dashboard", {replace: true});
        swal.close();
      }
      catch (err) {
        if (err.response){
          if (err.response.data.message){
            swal.fire({
              icon: 'error',
              title: 'Erreur !!',
              text: err.response.data.message
            }).then(() => {});
            return;
          }
        }

        swal.fire({
          icon: 'error',
          title: 'Erreur !!',
          text: 'Impossible de traiter la requête'
        }).then(() => {});
      }
      finally {
        swal.hideLoading();
      }

    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="Username"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Mot de Passe"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          //loading={isSubmitting}
        >
          Connexion
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
