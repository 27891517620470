import React, {useEffect, useMemo, useState} from "react";
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import {
    Button, MenuItem, Switch,
    Stack,
    Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, Chip,
    FormControlLabel, TextField, CircularProgress, Backdrop, Paper, Alert, Divider
} from "@mui/material";
import * as Yup from "yup";
import Iconify from "../../../components/Iconify";
import {useSwal} from '../../../hooks/swal';
import * as ApiSubscription from './../../../api/subscription';
import * as ppUtils from '../../../libs/utils';


const subscriptionDialogSchema = Yup.object().shape({
    taxi_offer_id: Yup.string().required("Le choix de l'offre du taxi est obligatoire"),
    echeance_number: Yup.number().required("Le choix de l'offre du taxi est obligatoire"),
});


const SubscriptionDialog = ({open, onClose, taxiOfferData, customerData}) => {

    const [isLoaderShow, setIsLoaderShow] = useState(false);
    const [isEchencePaymentChecked, setIsEchencePaymentChecked] = useState(false);

    const swal = useSwal();

    const handleClose = () => {
        formik.resetForm();
        onClose();
    };

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const handleEcheancePaymentCheckBoxChange = (e) => {
        setIsEchencePaymentChecked(e.target.checked);
    }

    const formik = useFormik({
        initialValues: {
            user_customer_id: "",
            taxi_offer_id: "",
            echeance_number: 0
        },
        validationSchema: subscriptionDialogSchema,
        onSubmit: async (values) => {
            swal.fire({
                title: 'Continuer ?',
                text: "Vous êtes sur le point d'effectuer une souscription",
                icon: 'warning',
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonText: 'Continuer',
                cancelButtonText: 'Annuler',
            })
                .then((rp) => {
                    if(!rp.isConfirmed) {
                        return;
                    }

                    showLoader();

                    ApiSubscription.addSubscription({...values, user_customer_id: customerData._id})
                        .then((data) => {

                            handleClose();

                            swal.fire({
                                icon: 'success',
                                title: "",
                                html:
                                    "L'opération à été effectuée avec succès <br /> <br /> <br />" +
                                    `Reference Souscription: ${data.subscription_ref} <br /><br />` +
                                    `Numero Reçu de paiement: ${data.receipt_number}`,
                            }).then(() => {});

                        })
                        .catch((err) => {

                            if (err.response){
                                swal.fire({
                                    icon: 'error',
                                    title: 'Erreur !!',
                                    text: err.response.data.message
                                }).then(() => {});
                                return;
                            }

                            swal.fire({
                                icon: 'error',
                                title: 'Erreur !!',
                                text: 'Impossible de traiter la requête'
                            }).then(() => {});

                        })
                        .finally(() => {
                            hideLoader();
                        })
                })
        },
        enableReinitialize: true
    });

    const { errors, touched, values, setValues, isSubmitting, handleSubmit, getFieldProps, isValid, submitForm } = formik;

    const selectedTaxiOffer = useMemo(() => {
        return taxiOfferData.find((item) => item._id === values.taxi_offer_id);
    }, [values.taxi_offer_id]);

    let formEcheancePaymentOption = [];

    // for mex_echance = 3, option is [2, 3], echence number 1 is skiped
    if (selectedTaxiOffer?.local_max_echeance_number > 1){
        ((new Array(selectedTaxiOffer.local_max_echeance_number)).fill(0))
            .forEach((item, index) => {
                if (index === 0){
                    return;
                }

                formEcheancePaymentOption.push(index + 1);
            })
    }

    let costByEcheanceToPay = 0;
    if (!!selectedTaxiOffer && values.echeance_number > 1){
        costByEcheanceToPay = ppUtils.normalizeAmount(Math.ceil(parseInt(selectedTaxiOffer.cost) / parseInt(values.echeance_number)));
    }

    useEffect(()  => {
        setIsEchencePaymentChecked(false);
        setValues((values) => ({...values, echeance_number: 0}));
    }, [values.taxi_offer_id]);

    useEffect(()  => {
        setValues((values) => ({...values, echeance_number: 0}));
    }, [isEchencePaymentChecked]);

    if (!customerData){
        return (
            <>
            </>
        );
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle> SOUSCRIPTION MON TAXI</DialogTitle>
                <DialogContent>

                    <DialogContentText sx={{mb: 4}}>
                    </DialogContentText>

                    <FormikProvider value={formik}>

                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3}>

                                <TextField
                                    fullWidth
                                    type="text"
                                    label="MATRICULE DU CLIENT"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    value={`${customerData.matricule}`}
                                />

                                <TextField
                                    fullWidth
                                    type="text"
                                    label="NOM DU CLIENT"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    value={`${customerData.first_name} ${customerData.last_name}`}
                                />

                                <TextField
                                    fullWidth
                                    select
                                    label="OFFRE MON TAXI"
                                    {...getFieldProps('taxi_offer_id')}
                                    error={Boolean(touched.taxi_offer_id && errors.taxi_offer_id)}
                                    helperText={touched.taxi_offer_id && errors.taxi_offer_id}
                                >

                                    {taxiOfferData.map((option) => (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}

                                </TextField>

                                {selectedTaxiOffer?.local_max_echeance_number > 1 && (
                                    <>
                                        <FormControlLabel label="Paiement par echenace ?" control={<Switch checked={isEchencePaymentChecked} onChange={handleEcheancePaymentCheckBoxChange}/>} />

                                        {isEchencePaymentChecked && (
                                            <>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    label="NOMBRE D'ECHEANCE"
                                                    {...getFieldProps('echeance_number')}
                                                    error={Boolean(touched.echeance_number && errors.echeance_number)}
                                                    helperText={touched.echeance_number && errors.echeance_number}
                                                >
                                                    {formEcheancePaymentOption.map((val) => (
                                                        <MenuItem key={val} value={val}>
                                                            {val}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </>
                                        )}
                                    </>
                                )}

                                <Divider variant="inset"><Chip label="RECAPUTILATIF" /></Divider>

                                <Paper elevation={4} sx={{p: 4, bgcolor: "#d8d8e0"}}>

                                    <strong>COUT DE L'OFFRE (FCFA) : </strong> <strong style={{float: "right"}}>{!!selectedTaxiOffer ? `${selectedTaxiOffer.cost}` : ""}</strong>
                                    <br />
                                    {(values.echeance_number < 1) ?
                                        (
                                            <>
                                                <div>
                                                    <strong>PAIEMENT PAR ECHEANCE ? :</strong> <Chip sx={{float: "right"}} size="small" label="NON" />
                                                </div>

                                                <div>
                                                    <strong>MONTANT À PAYER (FCFA) : </strong> <strong style={{float: "right"}}> {!!selectedTaxiOffer ? `${selectedTaxiOffer.cost}` : ""}</strong>
                                                </div>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <div>
                                                    <strong>PAIEMENT PAR ECHEANCE : </strong> <Chip sx={{float: "right"}} size="small" label="OUI" color="primary" />
                                                </div>

                                                <div>
                                                    <strong>NOMBRE D'ECHEANCE : </strong> <strong style={{float: "right"}}> {values.echeance_number}</strong>
                                                </div>

                                                <div>
                                                    <strong>MONTANT PAR D'ECHEANCE (FCFA) : </strong> <strong style={{float: "right"}}> {costByEcheanceToPay}</strong>
                                                </div>

                                            </>
                                        )
                                    }
                                </Paper>

                                <Alert severity="warning">
                                        La souscription à une offre est irreversible
                                </Alert>

                            </Stack>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>

                    <Button variant="contained" color="error" onClick={handleClose}>Annuler</Button>

                    <Button disabled={!isValid} variant="contained" color="success" onClick={submitForm}>Valider</Button>

                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

SubscriptionDialog.defaultProps = {
    taxiOfferData: [],
    customerData: null
};

SubscriptionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    taxiOfferData: PropTypes.array.isRequired,
    customerData: PropTypes.object,
};

export default SubscriptionDialog;
