import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';
import {useState} from 'react';

const Cswal = withReactContent(Swal);

export const useSwal = () => {
    return Cswal;
};
