import React, {useCallback, useEffect, useState} from 'react';
import Page from '../../../components/Page';
import {
    Button,
    Container,
    Stack,
    Typography,
    CircularProgress,
    Backdrop, Chip, Tooltip
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import Iconify from "../../../components/Iconify";
import CustomerDialog from './CustomerDialog';
import * as ApiCustomer from "../../../api/customer";
import {useSwal} from "../../../hooks/swal";
import * as ppUtils from '../../../libs/utils';


const Customer = () => {

    const swal = useSwal();

    const [userCustomerFetchData, setUserCustomerFetchData] = useState([]);

    const [isDialogCustomerOpen, setIsDialogCustomerOpen] = useState(false);

    const [isDialogCustomerOnReadOnly, setIsDialogCustomerOnReadOnly] = useState(false);

    const [dialogCustomerUserData, setDialogCustomerUserData] = useState(undefined);

    const [isLoaderShow, setIsLoaderShow] = useState(false);

    const tableColumns = [
        {
            field: '_id',
            headerName: 'ID',
            width: 400,
            renderCell: (params) => (
                <>
                    <Button 
                        href={`${process.env.REACT_APP_BACKEND_URL}/reports/customer/${params.row._id}`}
                        target="_blank" size="small" 
                        variant="contained"
                    >
                        fiche
                    </Button>
                    <Button 
                        sx={{ml: 1}} 
                        href={`${process.env.REACT_APP_BACKEND_URL}/reports/customer/${params.row._id}/_download`}
                        target="_blank" size="small" 
                        variant="contained"
                    >
                        Tel fiche
                    </Button>
                    <Button sx={{ml: 1}}  href={`/backoffice/customer-sheet/${params.row._id}`} target="_blank" size="small" variant="contained">info</Button>
                    <Button sx={{ml: 1}} onClick={() => handleButtonShowCustomerClick(params.row)} size="small" variant="contained">voir</Button>
                    <Button sx={{ml: 1}} onClick={() => handleButtonEditCustomerClick(params.row)} size="small" variant="contained">modifier</Button>
                </>
            )
        },
        {field: 'matricule', headerName: 'Matricule', width: 150},
        {
            field: 'account_confirmed_at',
            headerName: 'Compte Confirmé ?',
            width: 300,
            renderCell: (params) => (!!params.row.account_confirmed_at ?
                (
                    <Chip label={`OUI - le ${ppUtils.formatDateTime(params.row.account_confirmed_at)}`} />
                )
                :
                (
                    <>
                        <Chip label="NON" />
                        {!params.row.account_confirmed_at &&
                            <>
                                <Tooltip title="Renvoyé le mail de confirmation de compte" arrow>
                                    <Button onClick={() => resendAccountConfirmationEmail(params.row._id)} sx={{ml: 1}} size="small">c email</Button>
                                </Tooltip>
                            </>
                        }
                    </>

                )
            ),
        },
        {field: 'last_name', headerName: 'Nom', width: 150},
        {field: 'first_name', headerName: 'Prenom', width: 150},
        {
            field: 'country_id',
            headerName: 'Pays',
            valueGetter: (params) => (params.row.country_id?.name),
            width: 150
        },
        {field: 'city', headerName: 'Ville', width: 150},
        {field: 'state', headerName: 'State', width: 150},
        {
            field: 'phone_number',
            headerName: 'Contact',
            valueGetter: (params) => (`${params.row.phone_number_prefix} ${params.row.phone_number}`),
            width: 150
        },
        {
            field: 'user_admin_id',
            headerName: 'Ins en ligne ?',
            valueGetter: (params) => (!params.row.user_admin_id ? "OUI" : "NON"),
            width: 150
        },
        {field: 'email', headerName: 'Email', width: 300}
    ];

    const handleDialogCustomerClose = useCallback(() => {
        setIsDialogCustomerOpen(false);
        setDialogCustomerUserData(undefined);
        setIsDialogCustomerOnReadOnly(false);
        fetchCustomer();
    }, []);

    const handleButtonAddCustomerClick = useCallback(() => {
        setIsDialogCustomerOnReadOnly(false);
        setDialogCustomerUserData(undefined);
        setIsDialogCustomerOpen(true);
    }, []);

    const handleButtonShowCustomerClick = useCallback((row) => {
        setIsDialogCustomerOnReadOnly(true);
        setDialogCustomerUserData(row);
        setIsDialogCustomerOpen(true);
    }, []);

    const handleButtonEditCustomerClick = useCallback((row) => {
        setIsDialogCustomerOnReadOnly(false);
        setDialogCustomerUserData(row);
        setIsDialogCustomerOpen(true);
    }, []);

    const handleButtonRefreshTableClick = useCallback(() => {
        fetchCustomer();
    }, []);

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const fetchCustomer = () => {
        showLoader();

        ApiCustomer.findAllCustomer()
            .then((data) => {
                setUserCustomerFetchData(data);
            })
            .catch((err) => {
                if (err.response){
                    swal.fire({
                        icon: 'error',
                        title: 'Erreur !!',
                        text: err.response.data.message
                    }).then(() => {});
                    return;
                }

                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: 'Impossible de traiter la requête'
                }).then(() => {});
            })
            .finally(() => {
                hideLoader();
            })
    };

    const resendAccountConfirmationEmail = (customerId) => {

        swal.fire({
            title: 'Continuer ?',
            text: "Vous êtes sur le point de renvoyer un mail de confirmation de compte",
            icon: 'warning',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        })
            .then((rp) => {
                if (!rp.isConfirmed) {
                    return;
                }

                showLoader();

                ApiCustomer.resendAccountConfirmationEmail(customerId)
                    .then((data) => {

                        if (!data.is_email_sent){
                            swal.fire({
                                icon: 'error',
                                title: "",
                                text: "Le mail de confirmation de compte n'a pas pu être envoyé"
                            }).then(() => {});
                            return;
                        }

                        swal.fire({
                            icon: 'success',
                            title: "",
                            text: "L'opération à été effectuée avec succès"
                        }).then(() => {});


                    })
                    .catch((err) => {
                        if (err.response){
                            swal.fire({
                                icon: 'error',
                                title: 'Erreur !!',
                                text: err.response.data.message
                            }).then(() => {});
                            return;
                        }

                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: 'Impossible de traiter la requête'
                        }).then(() => {});
                    })
                    .finally(() => {
                        hideLoader();
                    })

            })
    };


    useEffect(() => {
        fetchCustomer();
    }, []);


    return (
        <Page title="Backoffice | Client">
            <Container>

                <Typography variant="h4" sx={{ mb: 5 }}>
                    CLIENT
                </Typography>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>

                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="mdi:refresh" />}
                        onClick={handleButtonRefreshTableClick}
                    >
                        Actualisé
                    </Button>

                    <Button
                        variant="contained"
                        //component={RouterLink}
                        to="#"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={handleButtonAddCustomerClick}
                        sx={{ml: 2}}
                    >
                        Nouveau Client
                    </Button>
                </Stack>

                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row._id}
                        columns={tableColumns}
                        rows={userCustomerFetchData}
                        components={{ Toolbar: GridToolbar }}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        disableSelectionOnClick
                        rowsPerPageOptions={[20, 40, 50, 100]}
                    />
                </div>

            </Container>

            <CustomerDialog
                userData={dialogCustomerUserData}
                readOnly={isDialogCustomerOnReadOnly}
                onClose={handleDialogCustomerClose}
                open={isDialogCustomerOpen}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Page>
    );
};

export default Customer;
