import React from "react";
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/backoffice/dashboard',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'client',
    path: '/backoffice/customer',
    icon: getIcon('mdi:account')
  },
  {
    title: 'souscription',
    path: '/backoffice/subscription',
    icon: getIcon('mdi:clipboard-edit')
  },
  {
    title: 'prêt',
    path: '/backoffice/loan',
    icon: getIcon('mdi:account-cash')
  },
  {
    title: 'profile',
    path: '/backoffice/profile',
    icon: getIcon('mdi:file-account')
  },
  {
    title: 'Offre Mon Taxi',
    path: '/backoffice/mytaxi-offer',
    icon: getIcon('mdi:access-point')
  },
  {
    title: 'Historique Action',
    path: '/backoffice/admin-action-history',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'commission',
    path: '/backoffice/commission',
    icon: getIcon('mdi:cog-clockwise')
  },
  {
    title: 'rapport',
    path: '/backoffice/report',
    icon: getIcon('mdi:file-settings')
  }
];

export default sidebarConfig;
