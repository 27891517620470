import React, {useCallback, useMemo, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {
    Button,
    Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent,
    Stack, Select, MenuItem, FormControl, InputLabel,
    TextField, CircularProgress, Backdrop
} from "@mui/material";
import {useSwal} from '../../../hooks/swal';
import * as ApiCommissionRegistering from "../../../api/commission_registering";
import { DatePicker, LocalizationProvider,  } from '@mui/x-date-pickers'
import { AdapterMoment }  from '@mui/x-date-pickers/AdapterMoment';
import "moment/locale/fr";


const CommissionRegisteringDialogUniq = ({open, onClose, subscriptionList}) => {

    const swal = useSwal();

    const [formData, setFormData] = useState({
        periode: null,
        subscription_ref_code: "",
        payment_type: "",
        payment_proof: "",
        borrowed_amount_deducted: 0
    });

    const [isLoaderShow, setIsLoaderShow] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);

    const handleFormPeriodFieldChange = useCallback((value) => {
        setFormData((oldState) => ({...oldState, periode: value}));
    }, []);

    const handleFormFieldChange = (e) => {
        if (e.target.name === "subscription_ref_code"){

            const dt = subscriptionList.find((item) => {
                return item.reference_code === e.target.value;
            });

            if (dt){
                setSelectedSubscription(dt);
                console.log("new selected subscription", dt)
            }
            else{
                setSelectedSubscription(null);
            }
        }
        setFormData((oldState) => ({...oldState, [e.target.name]: e.target.value}));
    };

    const handleClose = () => {
        setFormData({
            periode: null,
            subscription_ref_code: "",
            payment_type: "",
            payment_proof: "",
            borrowed_amount_deducted: 0
        });
        onClose();
    };

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }



    const handleFormSubmit = () => {

        if (!formData.subscription_ref_code){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Sélectionnez la Souscription"
            }).then(() => {});
            return;
        }


        if (!formData.periode){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Le période est obligatoire"
            }).then(() => {});
            return;
        }

        if (!formData.payment_type){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Le type de paiement est obligatoire"
            }).then(() => {});
            return;
        }

        if (!formData.payment_proof){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "La preuve de paiement est obligatoire"
            }).then(() => {});
            return;
        }

        if(selectedSubscription?.loan){
            if (parseInt(formData.borrowed_amount_deducted) > (parseInt(selectedSubscription.loan.amount_paid) - parseInt(selectedSubscription.borrowed_amount))){
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Le Montant Dette à Prélever est supérieur au Montant Dette Restant Payer"
                }).then(() => {});
                return;
            }
        }

        swal.fire({
            title: 'Continuer ?',
            text: "Vous êtes sur le point de ",
            icon: 'warning',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        })
        .then((rp) => {
            if(!rp.isConfirmed) {
                return;
            }

            showLoader();

            ApiCommissionRegistering.addCommissionRegisteringUniq(formData)
                .then((data) => {
                    handleClose();

                    swal.fire({
                        icon: 'success',
                        title: "",
                        text: "L'opération à été effectuée avec succès"
                    }).then(() => {});

                })
                .catch((err) => {

                    if (err.response){
                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: err.response.data.message
                        }).then(() => {});
                        return;
                    }

                    swal.fire({
                        icon: 'error',
                        title: 'Erreur !!',
                        text: 'Impossible de traiter la requête'
                    }).then(() => {});

                })
                .finally(() => {
                    hideLoader();
                })
        })
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle> Commission Unique</DialogTitle>
                <DialogContent>

                    <DialogContentText sx={{mb: 4}}>
                    </DialogContentText>

                    <Stack spacing={3}>

                        <LocalizationProvider dateAdapter={AdapterMoment} local="fr">
                            <DatePicker
                                views={['year', 'month']}
                                label="Période"
                                name="periode"
                                value={formData.periode}
                                onChange={handleFormPeriodFieldChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Code de Reference Souscription</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                name="subscription_ref_code"
                                value={formData.subscription_ref_code}
                                onChange={handleFormFieldChange}
                                placeholder="Choisissez la souscription"
                            >
                                {subscriptionList.map((item) => {
                                    return <MenuItem key={item.reference_code} value={item.reference_code}>
                                        {`${item.reference_code} (${item.customer.first_name} ${item.customer.last_name})`}
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            type="text"
                            label="Commission Mensuel"
                            InputProps={{
                                readOnly: true
                            }}
                            value={selectedSubscription?.subscribed_taxi_offer.monthly_commission_amount}
                        />

                        {selectedSubscription?.loan && (
                            <>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Montant Dette Emprunté"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    value={`${selectedSubscription.loan.borrowed_amount}`}
                                />

                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Montant Dette Restant à Payer"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    value={`${(parseInt(selectedSubscription.loan.borrowed_amount) - parseInt(selectedSubscription.loan.amount_paid))}`}
                                />

                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Montant à Prélever"
                                    name="borrowed_amount_deducted"
                                    value={formData.borrowed_amount_deducted}
                                    onChange={handleFormFieldChange}
                                />
                            </>
                        )}

                        <TextField
                            fullWidth
                            type="text"
                            label="Type de Paiement"
                            name="payment_type"
                            value={formData.payment_type}
                            onChange={handleFormFieldChange}
                        />

                        <TextField
                            fullWidth
                            type="text"
                            label="Preuve de Paiement"
                            name="payment_proof"
                            value={formData.payment_proof}
                            onChange={handleFormFieldChange}
                        />

                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClose}>Annuler</Button>
                    <Button variant="contained" color="success" onClick={handleFormSubmit}>Valider</Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}


CommissionRegisteringDialogUniq.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    subscriptionList: PropTypes.array.isRequired,
};

export default CommissionRegisteringDialogUniq;
