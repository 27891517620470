import React, { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';
import {useSelector} from "react-redux";

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 20,
  left: 20,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
}));

// ----------------------------------------------------------------------

export default function BackofficeNoSidebarLayout() {
  const auth = useSelector(({auth}) => auth);
  const [open, setOpen] = useState(false);


  if (!auth?.isAuthenticated){
    return <Navigate to="/auth/login" replace={true} />;
  }


  return (
    <>
      <HeaderStyle>
        <Logo />
      </HeaderStyle>
      <Outlet />
    </>
  );
}
