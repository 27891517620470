// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../libs/formatNumber';
// component
import Iconify from '../../../components/Iconify';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme, color }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: "white",
  backgroundColor: color
}));

const IconWrapperStyle = styled('div')(({ theme, color }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: "white",
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------


const StatsCard = ({label, count, iconName, color}) => {
  return (
      <RootStyle color={color}>
        <IconWrapperStyle>
          <Iconify icon={iconName} width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="h3">{fShortenNumber(count)}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {label}
        </Typography>
      </RootStyle>
  );
};

StatsCard.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number,
  iconName: PropTypes.string,
  color: PropTypes.string,
};

export default StatsCard;
