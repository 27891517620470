import React, {useCallback, useMemo, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {
    Button,
    Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent,
    Stack, Select, MenuItem, FormControl, InputLabel,
    TextField, CircularProgress, Backdrop
} from "@mui/material";
import {useSwal} from '../../../hooks/swal';
import * as Apiloan from "../../../api/loan";
import "moment/locale/fr";
import validator from 'validator';


const LoanDialog = ({open, onClose, subscriptionList}) => {

    const swal = useSwal();

    const [formData, setFormData] = useState({
        subscription_ref_code: "",
        borrowed_amount: 0,
        comment: ""
    });

    const [isLoaderShow, setIsLoaderShow] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);

    const handleFormFieldChange = useCallback((e) => {

        if (e.target.name === "subscription_ref_code"){
            const dt = subscriptionList.find((item) => {
                return item.reference_code === e.target.value;
            });

            if (dt){
                setSelectedSubscription(dt);
                console.log("new selected subscription", dt)
            }
            else{
                setSelectedSubscription(null);
            }
        }
        setFormData((oldState) => ({...oldState, [e.target.name]: e.target.value}));
    }, []);

    const handleClose = () => {
        setFormData({
            subscription_ref_code: "",
            borrowed_amount: 0,
            comment: ""
        });
        onClose();
    };

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const handleFormSubmit = () => {


        if (!formData.subscription_ref_code){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Sélectionnez la souscription"
            }).then(() => {});
            return;
        }

        if(selectedSubscription?.loan){
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Il existe deja un prêt non soldé associé à la souscription"
            }).then(() => {});
            return;
        }

        if (!validator.isNumeric(formData.borrowed_amount)) {
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Entrez un Montant de Prêt valide"
            }).then(() => {});
            return;
          }
          else{
            if (parseInt(formData.borrowed_amount) <= 0){
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Entrez un Montant de Prêt valide"
                }).then(() => {});
                return;
            }
          }

        swal.fire({
            title: 'Continuer ?',
            text: "Vous êtes sur le point de ",
            icon: 'warning',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        })
        .then((rp) => {
            if(!rp.isConfirmed) {
                return;
            }

            showLoader();

            Apiloan.addLoan(formData)
                .then((data) => {
                    handleClose();

                    swal.fire({
                        icon: 'success',
                        title: "",
                        text: "L'opération à été effectuée avec succès"
                    }).then(() => {});

                })
                .catch((err) => {

                    if (err.response){
                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: err.response.data.message
                        }).then(() => {});
                        return;
                    }

                    swal.fire({
                        icon: 'error',
                        title: 'Erreur !!',
                        text: 'Impossible de traiter la requête'
                    }).then(() => {});

                })
                .finally(() => {
                    hideLoader();
                })
        })
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle> Prêt </DialogTitle>
                <DialogContent>

                    <DialogContentText sx={{mb: 4}}>
                    </DialogContentText>

                    <Stack spacing={3}>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Code de Reference Souscription</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                name="subscription_ref_code"
                                value={formData.subscription_ref_code}
                                onChange={handleFormFieldChange}
                                placeholder="Choisissez la souscription"
                            >
                                {subscriptionList.map((item) => {
                                    return <MenuItem key={item.reference_code} value={item.reference_code}>
                                        {`${item.reference_code} (${item.customer.first_name} ${item.customer.last_name})`}
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            type="text"
                            label="Montant Prêt (FCFA)"
                            name="borrowed_amount"
                            value={formData.borrowed_amount}
                            onChange={handleFormFieldChange}
                        />

                        {selectedSubscription?.loan && (
                            <>
                                <p>
                                    Cette souscription est deja associé à un prêt non soldé 
                                </p>
                            </>
                        )}

                        <TextField
                            fullWidth
                            type="text"
                            label="Commentaire"
                            name="comment"
                            value={formData.comment}
                            onChange={handleFormFieldChange}
                        />

                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClose}>Annuler</Button>
                    <Button variant="contained" color="success" onClick={handleFormSubmit}>Valider</Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}


LoanDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    subscriptionList: PropTypes.array.isRequired,
};

export default LoanDialog;
