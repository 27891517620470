import React from 'react';

import Page from '../../../components/Page';
import {
    Backdrop,
    CircularProgress,
    Container,
    Typography
} from "@mui/material";
import {useSwal} from "../../../hooks/swal";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import * as ApiCommon from "../../../api/common";
import * as ppUtils from '../../../libs/utils'
import {DataGrid, frFR, GridToolbar} from "@mui/x-data-grid";

const AdminActionHistory = () => {

    const swal = useSwal();
    const navigate = useNavigate();

    const [isLoaderShow, setIsLoaderShow] = useState(false);
    const [adminActionData, setAdminActionData] = useState([]);

    const tableColumns = [
        {field: 'action_type', headerName: 'Type', minWidth: 200},
        {
            field: 'user_admin_id',
            headerName: 'Admin',
            valueGetter: (params) => (`${params.row.user_admin_id.last_name} ${params.row.user_admin_id.first_name}`),
            width: 150
        },
        {field: 'message', headerName: 'Message', minWidth: 400},
        {
            field: 'added_at',
            headerName: 'Date',
            valueGetter: (params) => (`${ppUtils.formatDateTime(params.row.added_at)}`),
            width: 250
        }
    ];

    const showLoader = () => {
        setIsLoaderShow(true);
    }

    const hideLoader = () => {
        setIsLoaderShow(false);
    }

    const fetchLastAdminAction = () => {
        showLoader();
        ApiCommon.getLastAdminAction()
            .then((data) => {
                setAdminActionData(data);
            })
            .catch((err) => {
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Une erreur s'est produite"
                }).then(() => {
                    navigate(-1, {replace: true})
                });
            })
            .finally(() => {
                hideLoader();
            })
    };

    useEffect(() => {
        fetchLastAdminAction();
    }, []);

    return (
        <Page title="Backoffice | Historique Admin Action">
            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    HISTORIQUE ACTION ADMIN
                </Typography>

                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row._id}
                        columns={tableColumns}
                        rows={adminActionData}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        disableSelectionOnClick
                    />
                </div>

            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal + 1 }}
                open={isLoaderShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Page>
    );
};

export default AdminActionHistory;
